import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PopUp = (_: IconSvgProps) => {
  return (
    <IconSvg
      width="165"
      height="108"
      viewBox="0 0 165 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2066 113.336C10.3026 105.015 9.73236 99.2093 13.6351 90.0447C18.6334 78.3074 24.6224 69.5478 36.5623 64.2412C49.1082 58.6654 59.9523 56.8806 72.7976 62.1888C84.3327 66.9556 100.052 77.7164 99.0946 92.3366C98.2095 105.856 81.996 98.6247 76.8038 92.976C68.4786 83.9188 72.9931 69.2492 79.3375 60.3C86.7753 49.8086 100.641 45.3006 112.994 47.2142C125.512 49.1533 136.134 53.2567 149.064 51.9212"
        stroke="white"
        strokeOpacity="0.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        r="8.9213"
        transform="matrix(0.449073 -0.893495 0.893494 0.449074 149.063 51.92)"
        fill="#E03C31"
      />
    </IconSvg>
  );
};

export default PopUp;
