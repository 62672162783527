import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

const NavigationExpandMore = ({ color }: IconSvgProps) => {
  return (
    <IconSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M16.103 9.00001L12.223 12.88L8.343 9.00001C8.15617 8.81275 7.90252 8.70752 7.638 8.70752C7.37349 8.70752 7.11983 8.81275 6.933 9.00001C6.543 9.39001 6.543 10.02 6.933 10.41L11.523 15C11.913 15.39 12.543 15.39 12.933 15L17.523 10.41C17.913 10.02 17.913 9.39001 17.523 9.00001C17.133 8.62001 16.493 8.61001 16.103 9.00001Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};

export default NavigationExpandMore;
