import { addBreadcrumb } from "@sentry/nextjs";
import { addSeconds } from "date-fns";
import ky, { HTTPError } from "ky";
import { z } from "zod";
import { AVAILABLE_LOCALES } from "~/core/locale";
import { Auth0Token } from "~/packages/auth0-react-passwordless/src/Auth0Token";
import { verifyIdToken } from "~/packages/auth0-react-passwordless/src/idTokenVerifier";
import { PasswordlessQuery } from "~/packages/auth0-react-passwordless/src/type";
import { GenericError } from "../errors";

const httpClient = ky.create({ retry: 3 });

export type RequestOptions = {
  domain: string;
  clientId: string;
  audience: string;
  scope: string;
};

export const Auth0PostOauthTokenPayloadParser = z.object({
  access_token: z.string(),
  refresh_token: z.string(),
  id_token: z.string(),
  token_type: z.string(),
  scope: z.string(),
  expires_in: z.number(),
});

export type Auth0PostOauthTokenPayload = z.infer<
  typeof Auth0PostOauthTokenPayloadParser
>;

const convertTokenPayloadToAuth0Token = async (
  tokenPayload: Auth0PostOauthTokenPayload,
): Promise<Auth0Token> => {
  const idTokenPayload = await verifyIdToken(tokenPayload.id_token);

  const expiresAt = addSeconds(new Date(), tokenPayload.expires_in);

  addBreadcrumb({
    message: "token expiration",
    data: {
      expires_in: tokenPayload.expires_in,
      expiresAt,
    },
    level: "debug",
  });

  const auth0Token = {
    accessToken: tokenPayload.access_token,
    refreshToken: tokenPayload.refresh_token,
    idTokenPayload,
    scope: tokenPayload.scope,
    tokenType: tokenPayload.token_type,
    expiresAt,
  };

  return auth0Token;
};

export const passwordlessStartRequest = async (
  passwordlessQuery: PasswordlessQuery,
  options: RequestOptions,
  locale: AVAILABLE_LOCALES,
): Promise<void> => {
  const authParams: Record<string, string> = {
    audience: options.audience,
    scope: options.scope,
  };

  let response: Response | undefined = undefined;

  try {
    response = await httpClient.post(
      `https://${options.domain}/passwordless/start`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-request-language": locale,
        },
        body: JSON.stringify({
          client_id: options.clientId,
          connection: passwordlessQuery.channel,
          send: "code",
          ...(passwordlessQuery.channel === "email"
            ? { email: passwordlessQuery.username }
            : {}),
          ...(passwordlessQuery.channel === "sms"
            ? { phone_number: passwordlessQuery.username }
            : {}),
          authParams,
        }),
      },
    );
  } catch (err) {
    if (err instanceof HTTPError) {
      const json = await err.response.json();

      throw new GenericError(json.error, json.error_description);
    }

    throw err;
  }

  if (response.status !== 200) {
    throw new Error(
      `Invalid passwordless start response: HTTP code ${response.status} instead of 200`,
    );
  }
};

export const passwordlessLoginRequest = async (
  passwordlessQuery: PasswordlessQuery,
  verificationCode: string,
  options: RequestOptions,
  locale: AVAILABLE_LOCALES,
): Promise<Auth0Token> => {
  let response: Response | undefined = undefined;

  try {
    response = await httpClient.post(`https://${options.domain}/oauth/token`, {
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        grant_type: "http://auth0.com/oauth/grant-type/passwordless/otp",
        client_id: options.clientId,
        otp: verificationCode,
        realm: passwordlessQuery.channel === "email" ? "email" : "sms",
        username: passwordlessQuery.username,
        audience: options.audience,
        scope: options.scope,
        language: locale,
      }),
    });
  } catch (err) {
    if (err instanceof HTTPError) {
      const json = await err.response.json();

      throw new GenericError(json.error, json.error_description);
    }

    throw err;
  }

  if (response.status !== 200) {
    throw new Error(
      `Invalid passwordless login response: HTTP code ${response.status} instead of 200`,
    );
  }

  const tokenPayload = Auth0PostOauthTokenPayloadParser.parse(
    await response.json(),
  );

  const token = await convertTokenPayloadToAuth0Token(tokenPayload);

  return token;
};

export const refreshTokenRequest = async (
  refreshToken: string,
  options: RequestOptions,
  abortController?: AbortController,
): Promise<Auth0Token> => {
  let response: Response | undefined = undefined;

  try {
    response = await httpClient.post(`https://${options.domain}/oauth/token`, {
      signal: abortController?.signal,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        client_id: options.clientId,
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      }),
    });
  } catch (err) {
    if (err instanceof HTTPError) {
      const json = await err.response.json();

      throw new GenericError(json.error, json.error_description);
    }

    throw err;
  }

  if (response.status !== 200) {
    throw new Error(
      `Invalid refresh token response: HTTP code ${response.status} instead of 200`,
    );
  }

  const tokenPayload = Auth0PostOauthTokenPayloadParser.parse(
    await response.json(),
  );

  const token = await convertTokenPayloadToAuth0Token(tokenPayload);

  return token;
};
