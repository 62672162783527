import { AriaListBoxOptions, useListBox } from "@react-aria/listbox";
import { SelectState } from "@react-stately/select";
import React, { RefObject, useRef } from "react";
import styled from "styled-components";
import ListBoxItem from "~/guidelines/Form/Select/ListBox/ListBoxItem";

const ListBoxContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  outline: none;
`;

type OptionListProps<T> = {
  options: AriaListBoxOptions<T>;
  state: SelectState<T>;
  maxHeight?: number;
  listBoxRef?: RefObject<HTMLDivElement>;
};

function ListBox<T>({ options, state, ...props }: OptionListProps<T>) {
  const defaultListBoxRef = useRef() as RefObject<HTMLDivElement>;

  const { listBoxRef = defaultListBoxRef, maxHeight } = props;

  const { listBoxProps } = useListBox(
    {
      ...options,
      autoFocus: state.focusStrategy ?? true,
      disallowEmptySelection: true,
    },
    state,
    listBoxRef,
  );

  return (
    <ListBoxContainer
      {...listBoxProps}
      ref={listBoxRef}
      style={
        maxHeight !== undefined
          ? { maxHeight: `${maxHeight}px`, overflowY: "auto" }
          : undefined
      }
      data-body-scroll-lock-ignore
    >
      {[...new Array(state.collection.size)].map((_, index) => (
        <ListBoxItem key={index} index={index} state={state} />
      ))}
    </ListBoxContainer>
  );
}

export default ListBox;
