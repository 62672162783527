import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

const NavigationExpandLess = ({ color }: IconSvgProps) => {
  return (
    <IconSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M11.523 9.00002L6.933 13.59C6.543 13.98 6.543 14.61 6.933 15C7.323 15.39 7.953 15.39 8.343 15L12.233 11.12L16.113 15C16.503 15.39 17.133 15.39 17.523 15C17.913 14.61 17.913 13.98 17.523 13.59L12.933 9.00002C12.553 8.61002 11.913 8.61002 11.523 9.00002Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};

export default NavigationExpandLess;
