import NiceModal, { useModal } from "@ebay/nice-modal-react";
import React from "react";
import styled from "styled-components";
import Drawer from "~/guidelines/Drawer/Drawer";
import { heading6 } from "~/guidelines/Typography";

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(5)};
  ${heading6};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => theme.spacing(5)};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(5)};
`;

type LanguageModalSheetParameters = {
  title: string;
  content: React.ReactNode;
  footer: React.ReactNode;
};

const LanguageModalSheet = NiceModal.create<LanguageModalSheetParameters>(
  ({ title, footer, content }) => {
    const modal = useModal();

    return (
      <Drawer
        isOpen={modal.visible}
        title={title}
        header={<Header>{title}</Header>}
        onClose={() => {
          modal.hide();
        }}
        onAfterClose={() => {
          if (!modal.keepMounted) {
            modal.remove();
          }
        }}
        footer={<Footer>{footer}</Footer>}
      >
        <Content>{content}</Content>
      </Drawer>
    );
  },
);

export default LanguageModalSheet;
