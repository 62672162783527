import styled from "styled-components";

export type IconSvgProps = {
  color?: string;
};

const IconSvg = styled.svg<IconSvgProps>`
  color: ${({ color }) => color ?? `inherit`};
  flex-shrink: 0;
  transition: color 0.2s ease;
`;

export default IconSvg;
