import styled, { css } from "styled-components";
import mediaQueries from "~/guidelines/Theme/mediaQueries";

export const heading1 = css`
  font-family: "Bely Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 42px;

  ${mediaQueries.md()} {
    font-size: 100px;
    line-height: 100px;
  }

  color: ${({ theme }) => theme.color.text.blueDark};
`;

/**
 * @deprecated Use the css version
 */
export const Heading1 = styled.h2`
  margin: 0;
  ${heading1};
`;

export const heading2 = css`
  font-family: "Bely Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;

  ${mediaQueries.md()} {
    font-size: 45px;
    line-height: 56px;
  }

  color: ${({ theme }) => theme.color.text.blueDark};
`;

/**
 * @deprecated Use the css version
 */
export const Heading2 = styled.h2`
  margin: 0;
  ${heading2};
`;

export const heading3 = css`
  font-family: "Bely Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;

  ${mediaQueries.md()} {
    font-size: 28px;
    line-height: 39px;
  }

  color: ${({ theme }) => theme.color.text.blueDark};
`;

/**
 * @deprecated Use the css version
 */
export const Heading3 = styled.h3`
  margin: 0;
  ${heading3};
`;

export const heading4 = css`
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;

  ${mediaQueries.md()} {
    font-size: 28px;
    line-height: 33px;
  }

  color: ${({ theme }) => theme.color.text.blueDark};
`;

/**
 * @deprecated Use the css version
 */
export const Heading4 = styled.h4`
  margin: 0;
  ${heading4};
`;

export const heading5 = css`
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;

  ${mediaQueries.md()} {
    font-size: 20px;
    line-height: 22px;
  }

  color: ${({ theme }) => theme.color.text.blueDark};
`;

/**
 * @deprecated Use the css version
 */
export const Heading5 = styled.h5`
  margin: 0;
  ${heading5};
`;

export const heading6 = css`
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  ${mediaQueries.md()} {
    font-size: 17px;
  }

  color: ${({ theme }) => theme.color.text.blueDark};
`;

/**
 * @deprecated Use the css version
 */
export const Heading6 = styled.h6`
  margin: 0;
  ${heading6};
`;

export const heading7 = css`
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  color: ${({ theme }) => theme.color.text.blueDark};
`;

export const body1 = css`
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;

  color: ${({ theme }) => theme.color.text.blueDark};
`;

export const body1Bold = css`
  ${body1};

  font-weight: 600;
`;

/**
 * @deprecated Use the css version
 */
export const Body1 = styled.p`
  margin: 0;
  ${body1};
`;

export const body2 = css`
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 134%;

  color: ${({ theme }) => theme.color.text.blueDark};
`;

export const body2Bold = css`
  ${body2};

  font-weight: 600;
`;

export const body2Bolder = css`
  ${body2};

  font-weight: 700;
`;

/**
 * @deprecated Use the css version
 */
export const Body2 = styled.p`
  margin: 0;
  ${body2};
`;

export const body3 = css`
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  ${mediaQueries.md()} {
    font-size: 16px;
  }

  color: ${({ theme }) => theme.color.text.greyDark};
`;

export const information = css`
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: ${({ theme }) => theme.color.text.blueDark};
`;

/**
 * @deprecated Use the css version
 */
export const Information = styled.p`
  margin: 0;
  ${information};
`;

export const subtitle1 = css`
  font-family: "Right Grotesk Narrow", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  color: ${({ theme }) => theme.color.text.blueDark};
`;

/**
 * @deprecated Use the css version
 */
export const Subtitle1 = styled.p`
  margin: 0;
  ${subtitle1};
`;

export const subtitle2 = css`
  font-family: "Right Grotesk Narrow", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  color: ${({ theme }) => theme.color.text.blueDark};
`;

/**
 * @deprecated Use the css version
 */
export const Subtitle2 = styled.p`
  margin: 0;
  ${subtitle2};
`;

export const subtitle3 = css`
  font-family: "Right Grotesk Narrow", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 8%;
  text-transform: uppercase;

  color: ${({ theme }) => theme.color.text.blueDark};
`;

/**
 * @deprecated Replaced by caption1?
 */
export const caption = css`
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;

  color: ${({ theme }) => theme.color.text.blueDark};
`;

export const caption1 = css`
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;

  color: ${({ theme }) => theme.color.text.greyDark};
`;

/**
 * @deprecated Use the css version
 */
export const Caption = styled.p`
  margin: 0;
  ${caption};
`;

export const buttonLarge = css`
  font-family: "Right Grotesk Narrow", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  color: ${({ theme }) => theme.color.text.blueDark};
`;

/**
 * @deprecated Use the css version
 */
export const ButtonLarge = styled.span<{ underline?: boolean }>`
  ${buttonLarge};
  text-decoration-line: ${({ underline }) =>
    underline ? "underline" : "none"};
`;

export const buttonNormal = css`
  font-family: "Right Grotesk Narrow", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  color: ${({ theme }) => theme.color.text.blueDark};
`;

/**
 * @deprecated Use the css version
 */
export const ButtonNormal = styled.span<{ underline?: boolean }>`
  ${buttonNormal};
  text-decoration-line: ${({ underline }) =>
    underline ? "underline" : "none"};
`;

export const buttonSmall = css`
  font-family: "Right Grotesk Narrow", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  color: ${({ theme }) => theme.color.text.blueDark};
`;

/**
 * @deprecated Use the css version
 */
export const ButtonSmall = styled.span<{ underline?: boolean }>`
  ${buttonSmall};
  text-decoration-line: ${({ underline }) =>
    underline ? "underline" : "none"};
`;
