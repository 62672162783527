import { i18n } from "@lingui/core";
import { AVAILABLE_LOCALES } from "~/core/locale";
import localesData from "~/translations/localesData";
import { I18n, Messages } from "~/translations/types";

// Always load all pluralization configurations
for (const onePluralLocale in localesData) {
  i18n.loadLocaleData(onePluralLocale, localesData[onePluralLocale]);
}

export const globalI18n: I18n = i18n;

export const activateLocale = (
  i18nInstance: I18n,
  locale: AVAILABLE_LOCALES,
  messages: Messages,
) => {
  if (i18nInstance.locale === locale) {
    return;
  }

  i18nInstance.load(locale, messages);
  i18nInstance.activate(locale);
};
