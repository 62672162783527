import Cookies from "js-cookie";
import { useRouter } from "next/compat/router";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import usePutUserLocale from "~/applications/UserProfile/Api/UserSettings/usePutUserSettings";
import { AVAILABLE_LOCALES } from "~/core/locale";
import { useModalController } from "~/core/modal";
import { Button, IconOnlyButton } from "~/guidelines/Button";
import { Close } from "~/guidelines/Icon";
import LocaleSwitcher from "~/guidelines/LocaleSwitcher";
import mediaQueries, { useIsMdScreen } from "~/guidelines/Theme/mediaQueries";
import { body1 } from "~/guidelines/Typography";
import LanguageModalSheet from "~/localization/LocaleConflictResolver/LanguageModalSheet";
import { useAuth0 } from "~/packages/auth0-react-passwordless";

type LocaleObject = Record<AVAILABLE_LOCALES, string>;

const localeLabels: {
  title: LocaleObject;
  long: LocaleObject;
  short: LocaleObject;
  submit: LocaleObject;
  change: LocaleObject;
} = {
  title: {
    [AVAILABLE_LOCALES.en]: `Translate`,
    [AVAILABLE_LOCALES.fr]: `Traduire`,
  },
  long: {
    [AVAILABLE_LOCALES.en]: `Our content is also available in English.<br />Would you like to change the language of the site?`,
    [AVAILABLE_LOCALES.fr]: `Nos contenus sont également disponibles en français.<br />Souhaitez-vous changer de langue ?`,
  },
  short: {
    [AVAILABLE_LOCALES.en]: `Would you like to change the language of the site?`,
    [AVAILABLE_LOCALES.fr]: `Souhaitez-vous changer la langue du site ?`,
  },
  submit: {
    [AVAILABLE_LOCALES.en]: `Continue`,
    [AVAILABLE_LOCALES.fr]: `Continuer`,
  },
  change: {
    [AVAILABLE_LOCALES.en]: `Change`,
    [AVAILABLE_LOCALES.fr]: `Modifier`,
  },
};

const Bar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(3, 5)};
  background-color: ${({ theme }) => theme.color.background.creamDark};
  box-shadow: ${({ theme }) => theme.shadow.navigation};
`;

const Text = styled.div`
  ${body1};
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(3)};

  ${mediaQueries.md()} {
    gap: ${({ theme }) => theme.spacing(5)};
  }
`;

const LanguageBar = ({
  requestLocale,
}: {
  requestLocale: AVAILABLE_LOCALES;
}) => {
  const router = useRouter();
  const idMdScreen = useIsMdScreen();
  const [currentLocale, setCurrentLocale] =
    useState<AVAILABLE_LOCALES>(requestLocale);
  const LanguageModalSheetController = useModalController(LanguageModalSheet);

  const { user } = useAuth0();
  const putUserLocale = usePutUserLocale();

  const handleLocaleChange = useCallback(
    (newLocale: AVAILABLE_LOCALES) => {
      Cookies.set("localeConflict", "false", { expires: 30 });
      setCurrentLocale(newLocale);
      if (user)
        putUserLocale({
          userId: user.userId,
          newLocale: newLocale,
        });
    },
    [putUserLocale, user],
  );

  if (idMdScreen) {
    return (
      <Bar>
        <Text
          dangerouslySetInnerHTML={{ __html: localeLabels.long[requestLocale] }}
        />
        <Right>
          <LocaleSwitcher
            currentLocale={currentLocale}
            availableLocales={Object.values(AVAILABLE_LOCALES)}
            onLocaleChange={handleLocaleChange}
          />
          <Button
            onPress={() => {
              router?.push(router.asPath, undefined, {
                locale: currentLocale,
              });
            }}
          >
            {localeLabels.submit[requestLocale]}
          </Button>
          <IconOnlyButton
            size="large"
            kind="quaternary"
            onPress={() => {
              Cookies.set("localeConflict", "false", { expires: 30 });
              router?.push(router.asPath, undefined, {});
            }}
            icon={<Close />}
          />
        </Right>
      </Bar>
    );
  }

  return (
    <Bar>
      <Text
        dangerouslySetInnerHTML={{ __html: localeLabels.short[requestLocale] }}
      />
      <Right>
        <Button
          onPress={() => {
            LanguageModalSheetController.show({
              title: localeLabels.title[requestLocale],
              content: (
                <>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: localeLabels.long[requestLocale],
                    }}
                  />
                  <LocaleSwitcher
                    currentLocale={currentLocale}
                    availableLocales={Object.values(AVAILABLE_LOCALES)}
                    onLocaleChange={handleLocaleChange}
                  />
                </>
              ),
              footer: (
                <Button
                  fluid
                  onPress={() => {
                    router?.push(router.asPath, undefined, {
                      locale: currentLocale,
                    });
                  }}
                >
                  {localeLabels.submit[requestLocale]}
                </Button>
              ),
            });
          }}
        >
          {localeLabels.change[requestLocale]}
        </Button>
        <IconOnlyButton
          size="large"
          kind="quaternary"
          onPress={() => {
            Cookies.set("localeConflict", "false", { expires: 30 });
            router?.push(router.asPath, undefined, {});
          }}
          icon={<Close />}
        />
      </Right>
    </Bar>
  );
};

export default LanguageBar;
