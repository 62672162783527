import { useOption } from "@react-aria/listbox";
import { mergeProps } from "@react-aria/utils";
import { SelectState } from "@react-stately/select";
import { Node } from "@react-types/shared";
import React, { RefObject, useRef } from "react";
import styled from "styled-components";
import { Color } from "~/guidelines/Color";
import { body1, heading6 } from "~/guidelines/Typography";

const OptionItem = styled.div<{ isSelected: boolean; isFocused: boolean }>`
  min-height: 42px;
  padding: 10px 24px;
  background: ${({ isFocused, theme }) =>
    isFocused
      ? Color.fromString(theme.color.brand.blue).opacity(0.08).rgba()
      : "transparent"};
  outline: none;
  cursor: pointer;

  ${({ isSelected }) => (isSelected ? heading6 : body1)};
`;

type ListBoxOptionProps<T> = {
  item: Node<T>;
  state: SelectState<T>;
};

export const ListBoxOption = React.forwardRef<
  HTMLDivElement,
  ListBoxOptionProps<unknown>
>(function ListBoxOptionWithForwardedRef({ item, state }, forwardedRef) {
  const defaultOptionRef = useRef(null);
  const optionRef = (forwardedRef ??
    defaultOptionRef) as RefObject<HTMLDivElement>;

  const { optionProps, isFocused, isSelected } = useOption(
    {
      key: item.key,
      shouldSelectOnPressUp: true,
      shouldFocusOnHover: true,
    },
    state,
    optionRef,
  );

  return (
    <OptionItem
      ref={optionRef}
      {...mergeProps(optionProps)}
      isSelected={isSelected}
      isFocused={isFocused}
    >
      {item.rendered}
    </OptionItem>
  );
});
