/**
 * Code heavily inspired by `next-i18next`
 *
 * @see https://github.com/isaachinman/next-i18next/blob/d43be9b8055e222c34eb88a886d6b55cc488cdd8/src/appWithTranslation.tsx
 */
import { I18nProvider } from "@lingui/react";
import hoistNonReactStatics from "hoist-non-react-statics";
import type { AppProps as NextJsAppProps } from "next/app";
import React, { useEffect, useMemo } from "react";
import {
  AVAILABLE_LOCALES,
  DEFAULT_LOCALE,
  selectFirstAcceptedLocale,
} from "~/core/locale";
import { activateLocale, globalI18n } from "~/translations/i18n";
import {
  I18nPropsNamespace,
  ServerSideGeneratedI18nNamespace,
} from "~/translations/types";

type AppProps = NextJsAppProps & {
  pageProps: NextJsAppProps["pageProps"] & ServerSideGeneratedI18nNamespace;
};

/**
 * This is a HOC which wraps your _app:
 *
 * ```js
 * # _app.js
 * import { appWithTranslation } from 'next-i18next';
 *
 * const MyApp = ({ Component, pageProps }) => <Component {...pageProps} />;
 *
 * export default appWithTranslation(MyApp);
 *
 * The appWithTranslation HOC is primarily responsible for adding a I18nProvider (and configure it).
 * ```
 */
export const appWithTranslation = (
  WrappedComponent: React.ComponentType<AppProps> | React.ElementType<AppProps>,
) => {
  const AppWithTranslation = (props: AppProps) => {
    const i18nPropsNamespace = (props.pageProps._i18nPropsNamespace ??
      undefined) as I18nPropsNamespace | undefined;
    const { locale } = props.router;

    const expectedLocale = selectFirstAcceptedLocale(
      locale,
      DEFAULT_LOCALE,
      AVAILABLE_LOCALES.en,
    );

    // We use a useMemo to trigger the locale change on server side (useEffect is not run on server side).
    useMemo(
      () => {
        if (!i18nPropsNamespace) {
          console.error(
            "No internationalization context have been found. Did you forgot to load the translations in the NextJs `getStaticProps()` or `getServerProps()` method? `return { /* ... */, ...(await serverSideTranslations(locale)) };`",
          );
        }

        if (
          i18nPropsNamespace &&
          expectedLocale !== i18nPropsNamespace.initialLocale
        ) {
          console.error(
            `The loaded locale from the "getStaticProps()" and the one from the NextJs router does not match. NextJs router "${locale}". Active locale "${expectedLocale}". Loaded "${i18nPropsNamespace.initialLocale}"`,
          );
        }

        activateLocale(
          globalI18n,
          i18nPropsNamespace?.initialLocale ?? expectedLocale,
          i18nPropsNamespace?.initialMessages ?? {},
        );
      },
      [], // eslint-disable-line react-hooks/exhaustive-deps
    );

    // We use the useEffect to change locale on client side and avoid a react warning with the useMemo.
    useEffect(() => {
      if (!i18nPropsNamespace) {
        console.error(
          "No internationalization context have been found. Did you forgot to load the translations in the NextJs `getStaticProps()` or `getServerProps()` method? `return { /* ... */, ...(await serverSideTranslations(locale)) };`",
        );
      }

      if (
        i18nPropsNamespace &&
        expectedLocale !== i18nPropsNamespace.initialLocale
      ) {
        console.error(
          `The loaded locale from the "getStaticProps()" and the one from the NextJs router does not match. NextJs router "${locale}". Loaded "${i18nPropsNamespace.initialLocale}"`,
        );
      }

      activateLocale(
        globalI18n,
        i18nPropsNamespace?.initialLocale ?? expectedLocale,
        i18nPropsNamespace?.initialMessages ?? {},
      );
    }, [locale, i18nPropsNamespace, expectedLocale]);

    return (
      <I18nProvider i18n={globalI18n}>
        <WrappedComponent key={locale} {...props} />
      </I18nProvider>
    );
  };

  return hoistNonReactStatics(AppWithTranslation, WrappedComponent);
};
