export class Color {
  readonly red;
  readonly green;
  readonly blue;
  readonly alpha;

  private constructor(r: number, g: number, b: number, alpha: number) {
    this.red = r;
    this.green = g;
    this.blue = b;
    this.alpha = alpha;
  }

  static fromString(color: string): Color {
    let r = 0,
      g = 0,
      b = 0,
      a = 1;
    if (color.search("#") === 0) {
      color = color.replace("#", "");
      if (color.length === 3) {
        color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
      }
      r = parseInt(color.substring(0, 2), 16);
      g = parseInt(color.substring(2, 4), 16);
      b = parseInt(color.substring(4, 6), 16);
    } else if (color.search("rgba") === 0) {
      const groups = color.match(
        "rgba\\((?<r>[0-9]+), ?(?<g>[0-9]+), ?(?<b>[0-9]+), ?(?<a>[0-9.]+)\\)",
      )?.groups;
      if (!groups) {
        throw new Error(`Invalid color: ${color} for rgba pattern`);
      }
      r = parseInt(groups.r, 10);
      g = parseInt(groups.g, 10);
      b = parseInt(groups.b, 10);
      a = parseFloat(groups.a);
    } else {
      throw new Error(`The color: ${color} can't be handled`);
    }

    return new Color(r, g, b, a);
  }

  opacity(alpha: number): Color {
    return new Color(this.red, this.green, this.blue, alpha);
  }

  rgba(): string {
    return `rgba(${this.red}, ${this.green}, ${this.blue}, ${this.alpha})`;
  }
}
