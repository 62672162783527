import IdTokenVerifier from "idtoken-verifier";

const defaultOptions = {
  domain: process.env.AUTH0_DOMAIN as string,
  audience: process.env.AUTH0_API_IDENTIFIER as string,
  clientId: process.env.AUTH0_CLIENT_ID as string,
  identityNamespace: process.env.AUTH0_IDENTITY_NAMESPACE as string,
  scope: "openid profile email offline_access",
};

const storybookOptions = {
  domain: process.env.STORYBOOK_AUTH0_DOMAIN as string,
  audience: process.env.STORYBOOK_AUTH0_API_IDENTIFIER as string,
  clientId: process.env.STORYBOOK_AUTH0_CLIENT_ID as string,
  identityNamespace: "https://ggll.localhost",
  scope: defaultOptions.scope,
};

export const options = process.env.IS_STORYBOOK
  ? storybookOptions
  : defaultOptions;

export const idTokenVerifier = new IdTokenVerifier({
  issuer: `https://${options.domain.replace(/\/$/, "")}/`,
  audience: options.clientId,
  jwksCache: new Map(),
});
