import { Breakpoints } from "~/guidelines/Theme/Breakpoints";

const breakpoints: Breakpoints = {
  xs: "360px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1400px",
};

const theme = {
  breakpoints,

  color: {
    text: {
      blue: "#0D0762",
      blueDark: "#211747",
      greyDark: "#554F6D",
      red: "#E03C31",
      greyMouse: "#918DA0",
      white: "#FFFFFF",
      white80: "rgba(255, 255, 255, 0.8)",
      white60: "rgba(255, 255, 255, 0.6)",
      white40: "rgba(255, 255, 255, 0.4)",
      blueDarkmode: "#39347C",
      grey3: "#333333",
      orange: "#FE7624",
      green: "#E3AE46",
    },

    brand: {
      blue: "#10069F",
      blueDark: "#211747",
      red: "#E03C31",
      redDark: "#c02f25",
      brown: "#A47F74",
      brownDark: "#86665E",
      greyDark: "#554F6D",
    },

    hover: {
      blue: "#0D0762",
      red: "#C02F25",
      brown: "#86665E",
      white20: "rgba(255, 255, 255, 0.2)",
    },

    background: {
      black: "#000000",
      white: "#FFFFFF",
      cream: "#FEFCFA",
      creamDark: "#F6F2F0",
      greyLight: "#FAFAFA",
      blue: "#10069F",
      blue10: "rgba(16, 6, 159, 0.1)",
      blue80: "rgba(16, 6, 159, 0.8)",
      red: "#E03C31",
      red10: "rgba(224, 60, 49, 0.1)",
      red80: "rgba(224, 60, 49, 0.8)",
      orange: "#FE7624",
      orangeExtraLight: "#FE76243B",
      yellow: "#FFF4B6",
      greenLight: "#85FAB7B2",
      greenExtraLight: "#85FAB73B",
      green: "#4ECB71",
      orangeLight: "#FE76243B",
      blueLight: "#EDECF6",
      blueDark: "#0D0762",
      blueDarkMode: "#39347C",
      blueStamp: "#B5D6F4",
      pink: "#FBC7C3",
    },

    placeholder: {
      grey: "#c4c4c4",
    },

    overlay: {
      black04: "rgba(0, 0, 0, 0.4)",
    },

    line: {
      grey2C: "#2C2C2C",
      greyStroke: "#DADADA",
      greySeparator: "#E9E9E9",
      grey5: "#e0e0e0",
      grey9: "#999999",
      white: "#FFFFFF",
      white10: "rgba(255, 255, 255, 0.1)",
    },

    feedBack: {
      green: "#219653",
      red: "#E03C31",
      blueDark: "#211747",
    },
  },

  borderRadius: {
    /**
     * 4px
     */
    small: "4px",
    /**
     * 6px
     */
    default: "6px",
    /**
     * 8px
     */
    large: "8px",
    /**
     * 16px
     */
    big: "16px",
  },

  shadow: {
    lineTop:
      "rgba(46, 59, 66, 0.125) 0px -1px 0px 0px, rgb(255, 255, 255) 0px 2px 0px 0px",
    paper:
      "0px 0px 4px rgba(0, 0, 0, 0.07), 0px 4px 40px 30px rgba(0, 0, 0, 0.04)",
    base: "0px 34px 60px -20px rgba(0, 0, 0, 0.1)",
    navigation:
      "0px 0px 3px rgba(0, 0, 0, 0.1), 0px 7px 16px rgba(82, 62, 56, 0.07)",
    navigationNone:
      "0px 0px 3px rgba(0, 0, 0, 0), 0px 7px 16px rgba(82, 62, 56, 0)",
    stampShadow: "0px 34px 60px rgba(0, 0, 0, 0.1)",
  },

  dropShadow: {
    navigation:
      "drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 7px 16px rgba(82, 62, 56, 0.07))",
  },

  outline: {
    base: {
      outline: "2px solid #10069F",
      offset: "2px",
    },
  },

  /**
   * When we have remove the `space` key we could rename this method to `space(6)`
   * | Index | Value |
   * |-------|-------|
   * | 0     | 0px   |
   * | 1     | 2px   |
   * | 2     | 4px   |
   * | 3     | 8px   |
   * | 4     | 12px  |
   * | 5     | 16px  |
   * | 6     | 24px  |
   * | 7     | 32px  |
   * | 8     | 40px  |
   * | 9     | 48px  |
   * | 10    | 56px  |
   * | 11    | 64px  |
   * | 12    | 72px  |
   * | 13    | 80px  |
   * | 14    | 88px  |
   * | 15    | 96px  |
   *
   */
  spacing(...offsets: Array<number | "auto">): string {
    const baseSize = [0, 2, 4, 8, 12, 16];

    return offsets
      .map((offset) => {
        if (offset === "auto") {
          return "auto";
        }

        if (offset < baseSize.length) {
          return `${baseSize[offset]}px`;
        }

        return `${16 + (offset - baseSize.length + 1) * 8}px`;
      })
      .join(" ");
  },
};

export default theme;
