import { idTokenVerifier } from "~/clients/Auth0";
import { Auth0IdTokenPayload, Auth0IdTokenPayloadSchema } from "./Auth0Token";

export const verifyIdToken = (
  unTrustedIdToken: string,
): Promise<Auth0IdTokenPayload> => {
  return new Promise((resolve, reject) => {
    idTokenVerifier.verify(
      unTrustedIdToken,
      // The generated id_token does not include the nonce: we could not use it to verify the id_token.
      undefined,
      (error, payload) => {
        if (error) {
          reject(error);
          return;
        }

        resolve(payload);
      },
    );
  }).then((payload) => {
    const result = Auth0IdTokenPayloadSchema.safeParse(payload);
    if (!result.success) {
      throw result.error.flatten();
    }

    return result.data;
  });
};
