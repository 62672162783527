import { t } from "@lingui/macro";
import { Analytics } from "@vercel/analytics/react";
import { AppProps } from "next/app";
import Head from "next/head";
import React from "react";
import { apiHost as ContentStudioApiHost } from "~/clients/ContentStudio";
import ApplicationKernel from "~/core/ApplicationKernel";
import theme from "~/guidelines/Theme/theme";
import { appWithTranslation } from "~/translations/appWithTranslation";
import "photoswipe/dist/photoswipe.css";
import "react-day-picker/dist/style.css";

type GogailleAppProps = AppProps & {
  /**
   * To help error handling with Sentry
   * @see https://github.com/vercel/next.js/blob/dd7a54c5099c781273b01f97788e980fd203e188/examples/with-sentry/pages/_app.js
   */
  err?: Error;
};

const GogailleApp = ({ Component, pageProps, err }: GogailleAppProps) => {
  return (
    <ApplicationKernel
      applicationEnvironment={
        process.env.NODE_ENV === "production" ? "production" : "development"
      }
    >
      <Head>
        <title>Gogaille</title>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta
          name="description"
          content={t`Gogaille reinvents hospitality and the art of tasting it!`}
        />

        <link rel="shortcut icon" href="/assets/favicon.ico?v=2" />
        <link rel="manifest" href="/assets/site.webmanifest?v=2" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/apple-touch-icon.png?v=2"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/favicon-32x32.png?v=2"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/favicon-16x16.png?v=2"
        />
        <link
          rel="mask-icon"
          href="/assets/safari-pinned-tab.svg?v=2"
          color={theme.color.brand.red}
        />
        <meta
          name="msapplication-TileColor"
          content={theme.color.background.cream}
        />
        <meta
          name="msapplication-config"
          content="/assets/browserconfig.xml?v=2"
        />
        <meta name="theme-color" content={theme.color.background.cream}></meta>

        {process.env.IBE_ENV === "production" ? (
          <meta
            name="google-site-verification"
            content="lTpBBH5axmhaO0S1VuRY9Mxzm7gJZutjEdbVapqI-BI"
          />
        ) : undefined}
        <meta
          name="facebook-domain-verification"
          content="2ldu2djxh3dsfxd3bihsa2oitpl9ou"
        />
        <link rel="preconnect" href={ContentStudioApiHost} />
      </Head>
      <Component {...pageProps} err={err} />
      {process.env.IBE_ENV === "production" ? <Analytics /> : null}
    </ApplicationKernel>
  );
};

export default appWithTranslation(GogailleApp);
