import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

const Close = ({ color }: IconSvgProps) => {
  return (
    <IconSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M18.528 5.71002C18.3412 5.52276 18.0875 5.41753 17.823 5.41753C17.5585 5.41753 17.3048 5.52276 17.118 5.71002L12.228 10.59L7.338 5.70002C7.15117 5.51276 6.89752 5.40753 6.633 5.40753C6.36848 5.40753 6.11483 5.51276 5.928 5.70002C5.538 6.09002 5.538 6.72002 5.928 7.11002L10.818 12L5.928 16.89C5.538 17.28 5.538 17.91 5.928 18.3C6.318 18.69 6.948 18.69 7.338 18.3L12.228 13.41L17.118 18.3C17.508 18.69 18.138 18.69 18.528 18.3C18.918 17.91 18.918 17.28 18.528 16.89L13.638 12L18.528 7.11002C18.908 6.73002 18.908 6.09002 18.528 5.71002Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};

export default Close;
