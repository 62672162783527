import { z } from "zod";
import { ViolationsSchema } from "~/core/api/commons";
import { ViolationCode, mapViolationCode } from "~/core/api/violationList";

export type Violation = {
  code: ViolationCode;
  message: string;
  propertyPath: string;
};

export type ViolationList = Array<Violation>;

export const parseViolationsPayload = (
  violationsPayload: z.infer<typeof ViolationsSchema>,
): ViolationList => {
  return violationsPayload.violations.map((oneViolation) => {
    return {
      code: mapViolationCode(oneViolation.code || ""),
      message: oneViolation.message,
      propertyPath: oneViolation.propertyPath,
    };
  });
};
