import * as qs from "qs";
import { StepId } from "~/applications/Checkout/Ui/CheckoutProcessWizardForm";
import CheckoutPublicState from "~/applications/Checkout/Ui/CheckoutPublicState";
import { CancellationPolicyId } from "~/applications/OfferSearch/Domain/CancellationPolicyId";
import { OpeningResult } from "~/applications/OpenAccess/Domain/OpeningResult";
import { OpeningTechnology } from "~/applications/OpenAccess/Domain/OpeningTechnology";
import StayRequest from "~/core/StayRequest";

export type Route = string;

export enum Referrer {
  CHECKOUT = "checkout",
  OTA = "ota",
}

const recordToQueryString = (data: Record<string, unknown>) =>
  qs.stringify(data, { encodeValuesOnly: true, arrayFormat: "repeat" });

export const gogailleHomepageRoute = (): Route => {
  return "/";
};

export const identityCheckPageRoute = (reservationId: string): Route =>
  `/identity-check/${reservationId}`;

export const destinationHomepageRoute = (destinationSlug: string): Route =>
  `/destinations/${destinationSlug}`;

export const destinationEchoppeRoute = (destinationSlug: string): Route =>
  `/destinations/${destinationSlug}/echoppes`;

export const offersRoute = (
  destinationSlug: string,
  stayRequest: StayRequest | undefined,
  cancellationPolicyId: CancellationPolicyId | undefined,
  logeIds?: Array<string> | undefined,
): Route => {
  const stayRequestQueryString = stayRequest
    ? "?" + recordToQueryString({ ...stayRequest.toJSON() })
    : "";

  const cancellationPolicyIdQueryString = cancellationPolicyId
    ? `&cancellationPolicy=${cancellationPolicyId}`
    : "";

  const logeIdQueryString =
    logeIds !== undefined ? `&logeIds=${logeIds?.join(",")}` : "";

  return `/destinations/${destinationSlug}/offers${stayRequestQueryString}${cancellationPolicyIdQueryString}${logeIdQueryString}`;
};

export const offerSearchUnitGroupRoute = (
  destinationSlug: string,
  logeSlug: string,
  unitGroupSlug: string,
  stayRequest: StayRequest | undefined,
  cancellationPolicyId: CancellationPolicyId | undefined,
): Route => {
  const stayRequestQueryString = stayRequest
    ? "?" + recordToQueryString({ ...stayRequest.toJSON() })
    : "";

  const cancellationPolicyIdQueryString = cancellationPolicyId
    ? `&cancellationPolicy=${cancellationPolicyId}`
    : "";

  return `/destinations/${destinationSlug}/${logeSlug}/${unitGroupSlug}${stayRequestQueryString}${cancellationPolicyIdQueryString}`;
};

export const newBookingEngineSessionRoute = (
  stayRequest: StayRequest,
  logeId: string,
  unitGroupId: string,
  cancellationPolicyId: CancellationPolicyId,
): Route => {
  return `/checkout/new?${recordToQueryString({
    ...stayRequest.toJSON(),
    logeId,
    unitGroupId,
    cancellationPolicyId,
    travelPurpose: stayRequest.travelPurpose,
  })}`;
};

export const bookingEngineRoute = (
  stepId: StepId,
  checkoutPublicState: CheckoutPublicState,
): Route => {
  return `/checkout/step/${stepId}?${recordToQueryString(
    checkoutPublicState.toJSON(),
  )}`;
};

export const reservationConfirmedRoute = (
  reservationId: string,
  referrer: Referrer | null = null,
): Route =>
  `/reservation/${reservationId}?${recordToQueryString({ referrer })}`;

export const gogailleJourneyRoute = (): Route => "/me/journey";

export const gogailleJourneyDetailsRoute = (reservationId: string): Route =>
  `/me/journey/${reservationId}`;

export const gogailleProfileRoute = (): Route => "/me/profile";

export const gogailleHelpRoute = (): Route => "/me/help";

export const gogailleSavedGuestRoute = (): Route => "/me/profile/saved-guests";

export const gogailleBillingAddressesRoute = (): Route =>
  "/me/profile/billing-addresses";

export const gogaillePreferencesRoute = (): Route => "/me/profile/preferences";

export const gogailleOpenAccessRoute = (
  accessId: string,
  technology: OpeningTechnology,
  openingResult?: OpeningResult,
): Route =>
  `/open-access/${accessId}?${recordToQueryString({
    technology,
    openingResult,
  })}`;

export const gogailleTermsGeneralConditionsRoute = () =>
  "/terms/general-conditions";

export const gogailleLegalMentionsRoute = () => "/legal/mentions";

export const gogaillePrivacyPolicyRoute = () => "/privacy/policy";

export const gogaillePrivacyDataProtectionRoute = () =>
  "/privacy/data-protection";

export const gogaillePrivacyCookiesRoute = () => "/privacy/cookies";

export const gogailleStartOTAOnboarding = (token: string) =>
  `/onboarding/ota/start?token=${token}`;

export const gogailleOTACheckPhoneNumber = (reservationId: string) =>
  `/onboarding/ota/check-phone-number?reservationId=${reservationId}`;

export const gogailleDoorTutorialVideoRoute = () => `/youtube-door-tutorial`;

export const gogailleQRCodeDoorOpeningRoute = (reservationId: string) =>
  `/open-access/qr-code?reservationId=${reservationId}`;
