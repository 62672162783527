import { useListBoxSection } from "@react-aria/listbox";
import { SelectState } from "@react-stately/select";
import { Node } from "@react-types/shared";
import React from "react";
import styled from "styled-components";
import { heading5 } from "~/guidelines/Typography";

const SectionItem = styled.div`
  min-height: 42px;
  padding: 10px 24px;
  outline: none;
  ${heading5};
`;

type ListBoxSectionProps<T> = {
  item: Node<T>;
  state: SelectState<T>;
};

export const ListBoxSection = React.forwardRef<
  HTMLDivElement,
  ListBoxSectionProps<unknown>
>(function ListBoxSectionWithForwardedRef({ item }, forwardedRef) {
  const { itemProps } = useListBoxSection({
    heading: item.rendered,
    "aria-label": item["aria-label"],
  });

  const label =
    item["aria-label"] ??
    (typeof item.rendered === "string" ? item.rendered : item.textValue);

  return (
    <div
      ref={forwardedRef}
      role="presentation"
      aria-label={label}
      {...itemProps}
    >
      {item.rendered && <SectionItem>{item.rendered}</SectionItem>}
    </div>
  );
});
