import { FocusRing as ReactAriaFocusRing } from "@react-aria/focus";
import { ComponentProps } from "react";

export const focusRingClassName = "focus-ring";

export function FocusRing({
  children,
  ...props
}: Omit<
  ComponentProps<typeof ReactAriaFocusRing>,
  "focusClass" | "focusRingClass"
>) {
  return (
    <ReactAriaFocusRing {...props} focusRingClass={focusRingClassName}>
      {children}
    </ReactAriaFocusRing>
  );
}
