import { formatISO } from "date-fns";
import Script from "next/script";
import React from "react";
import WIHPId from "~/applications/Journey/Domain/WIHPId";

type WIHPTrackerProps = {
  applicationEnvironment: "development" | "production" | "test" | "storybook";
  wihpId: WIHPId | null;
  reservationId: string;
  totalAmountWithoutTax: number;
  checkin: Date;
  checkout: Date;
};

export const PaymentConfirmationWIHPTracker = ({
  applicationEnvironment,
  wihpId,
  reservationId,
  totalAmountWithoutTax,
  checkin,
  checkout,
}: WIHPTrackerProps) => {
  if (applicationEnvironment !== "production" || wihpId === null) {
    return <div className={`wihp-${wihpId ? wihpId : "unknownId"}-debugger`} />;
  }

  return (
    <Script
      id={`wihp-${wihpId ? wihpId : "unknownId"}-tracker`}
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          var _wic = _wic || [];
          _wic.push(['_setHotel', '${wihpId}']);
          _wic.push(['_setRef', '${reservationId}']); 
          _wic.push(['_setAmount', '${totalAmountWithoutTax}']); 
          _wic.push(['_setCurrency', 'EUR']); 
          _wic.push(['_setIDBE', '3']);
          _wic.push(['_setCheckin', '${formatISO(checkin, {
            representation: "date",
          })}']);
          _wic.push(['_setCheckout', '${formatISO(checkout, {
            representation: "date",
          })}']);
          _wic.push(['_setDateFormat', 'YYYY-MM-DD']);
  
          (function() {
              var wh = document.createElement('script'); wh.type = 'text/javascript'; wh.async = true;
              wh.src = 'https://p.relay-t.io/conv.js';
              var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(wh, s);
          })();
    `,
      }}
    />
  );
};

export const GlobalWIHPTracker = ({
  applicationEnvironment,
}: {
  applicationEnvironment: "development" | "production" | "test" | "storybook";
}) => {
  if (applicationEnvironment !== "production") {
    return <div className="wihp-global-debugger" />;
  }

  return (
    <Script
      id="wihp-global-tracker"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
        (function() {
            var wh = document.createElement('script'); wh.type = 'text/javascript'; wh.async = true;
            wh.src = 'https://p.relay-t.io/wh.js';
            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(wh, s);
        })();
        `,
      }}
    />
  );
};
