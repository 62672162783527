"use client";

import { useModal } from "@ebay/nice-modal-react";
import { useRouter } from "next/compat/router";
import React, { useEffect } from "react";

/**
 * The `modalIdentifier` could also be a string (but hard to type for now)
 */
export function useModalController<ModalIdentifier extends React.FC<any>>(
  modalIdentifier: ModalIdentifier,
) {
  const { show, hide, visible } = useModal(modalIdentifier);

  const router = useRouter();

  useEffect(() => {
    if (!router) {
      return;
    }

    const routeChangeStartHandler = () => hide();

    router.events.on("routeChangeStart", routeChangeStartHandler);

    return () => {
      if (!router) {
        return;
      }

      router.events.off("routeChangeStart", routeChangeStartHandler);
    };
  }, [hide, router, router?.events]);

  return {
    show,
    hide,
    isVisible: visible,
  };
}
