import sanityClientFactory, { SanityClient } from "@sanity/client";

const projectId = process.env.SANITY_PROJECT_ID as string;
const dataset = process.env.SANITY_DATASET as string;
const token = process.env.SANITY_READ_ONLY_TOKEN as string;
export const apiHost = process.env.SANITY_API_HOST as string;

export const contentStudioClient: SanityClient = sanityClientFactory(
  process.env.IBE_ENV !== "test"
    ? {
        apiHost,
        projectId,
        dataset,
        token,
        useCdn: true,
        ignoreBrowserTokenWarning: true, // @see https://www.sanity.io/help/js-client-browser-token
        apiVersion: "2021-06-25",
      }
    : {
        apiHost,
        projectId,
        dataset,
        useCdn: false, // Just to remove the warning. In test we will should never use the true API
        /**
         * By default the client add the projectId as a subdomain of the configured `apiHost`:
         *    "http://localhost:1080/sanity" -> "http://fake-project-id.localhost:1080/sanity"
         *
         * This trigger an exception into MockServer: `java.net.UnknownHostException: fake-sanity-project-id.localhost`
         * Maybe MockServer have an option to configure this?
         *
         * @FIXME useProjectHostname is deprecated
         */
        useProjectHostname: false,
        apiVersion: "2021-06-25",
      },
);
