import sanityImageUrlBuilder from "@gogaille/sanity-image-url";
import { createContext, useContext, useMemo } from "react";

export interface SanityProjectDetails {
  projectId?: string;
  dataset?: string;
  baseUrl?: string;
}

export const SanityClientContext = createContext<SanityProjectDetails>({
  projectId: undefined,
  dataset: undefined,
  baseUrl: undefined,
});

/**
 * @deprecated Using a hook for that is hover kill. Use a lib/sanity.js file to configure the image url builder and import it directly.
 * @see https://github.com/sanity-io/next-sanity
 */
export const useImageUrlBuilder = () => {
  const { projectId, dataset, baseUrl } = useContext(SanityClientContext);

  const imageUrlBuilder = useMemo(() => {
    if (!projectId) {
      throw new Error("Sanity projectId is missing");
    }

    if (!dataset) {
      throw new Error("Sanity dataset is missing");
    }

    return sanityImageUrlBuilder({ projectId, dataset }).withOptions({
      baseUrl,
    });
  }, [projectId, dataset, baseUrl]);

  return imageUrlBuilder;
};
