export enum Orientation {
  "portrait" = "portrait",
  "landscape" = "landscape",
}

export enum Breakpoint {
  "xs" = "xs",
  "sm" = "sm",
  "md" = "md",
  "lg" = "lg",
  "xl" = "xl",
  "xxl" = "xxl",
}

export type Breakpoints = Record<Breakpoint, string>;
