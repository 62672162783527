import { useMemo } from "react";
import {
  AVAILABLE_LOCALES,
  DEFAULT_LOCALE,
  processUserLocale,
} from "~/core/locale";
import { isClientSide } from "~/core/ssr";
import { useLocale } from "~/core/useLocale";

type HookResult = {
  urlLocale: AVAILABLE_LOCALES;
  requestLocale: AVAILABLE_LOCALES;
  isSameLocale: boolean;
};

const useLocaleConflict = (): HookResult => {
  const locale = useLocale();
  const requestLocale = useMemo(() => {
    if (isClientSide()) {
      return navigator.languages;
    }

    return undefined;
  }, []);

  const newLocale = processUserLocale({
    acceptLanguage: requestLocale?.join(",") || "",
    defaultLocale: DEFAULT_LOCALE,
  });

  return {
    urlLocale: locale,
    requestLocale: newLocale,
    isSameLocale: locale === newLocale,
  };
};

export { useLocaleConflict };
