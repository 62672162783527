import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { contentStudioClient } from "~/clients/ContentStudio";
import { ALL_RESOURCE_VISIBILITY } from "~/core/ResourceVisibility";
import { buildGroqQuery } from "~/core/api/Sanity/query";

const getAllDestinationPagesQuery = () =>
  buildGroqQuery(`
  *[_type == 'destinationWebsite' && visibility.value in $visibilities && !(_id in path('drafts.**'))] | order(seoSlug.current)
  {
    seoSlug,
  }
`);

const AllDestinationPagesPayloadSchema = z.array(
  z.object({
    seoSlug: z.object({
      current: z.string(),
    }),
  }),
);

export async function fetchDestinationPages(): Promise<
  Array<{
    seoSlug: string;
  }>
> {
  const response = await contentStudioClient.fetch(
    getAllDestinationPagesQuery(),
    {
      visibilities: ALL_RESOURCE_VISIBILITY,
    },
  );

  const allDestinationPagesPayload =
    AllDestinationPagesPayloadSchema.parse(response);

  return allDestinationPagesPayload.map((oneDestinationPagePayload) => {
    return {
      seoSlug: oneDestinationPagePayload.seoSlug.current,
    };
  });
}

export const useDestinationPageSlugs = (): {
  destinationPagesSlugs: Array<{
    seoSlug: string;
  }> | null;
} => {
  const { data: destinationPagesSlugs = null } = useQuery(
    ["all-destinations-pages-list"],
    () => fetchDestinationPages(),
  );

  return { destinationPagesSlugs };
};
