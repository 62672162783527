/* eslint-disable react/no-unknown-property */
import React from "react";

export const GlobalFontFaces = () => (
  <>
    <link
      key="preconnect-gogaille-assets"
      rel="preconnect"
      href="https://gogaille-assets.vercel.app"
    />

    <style jsx global>{`
      @font-face {
        font-family: "Bely Display";
        font-style: normal;
        font-display: fallback;
        font-weight: 400;
        src:
          url("https://gogaille-assets.vercel.app/fonts/bely-display-400.woff2")
            format("woff2"),
          url("https://gogaille-assets.vercel.app/fonts/bely-display-400.woff")
            format("woff");
      }

      @font-face {
        font-family: "Public Sans";
        font-style: normal;
        font-display: fallback;
        font-weight: 400;
        src:
          url("https://gogaille-assets.vercel.app/fonts/public-sans-400.woff2")
            format("woff2"),
          url("https://gogaille-assets.vercel.app/fonts/public-sans-400.woff")
            format("woff");
      }

      @font-face {
        font-family: "Public Sans";
        font-style: normal;
        font-display: fallback;
        font-weight: 500;
        src:
          url("https://gogaille-assets.vercel.app/fonts/public-sans-500.woff2")
            format("woff2"),
          url("https://gogaille-assets.vercel.app/fonts/public-sans-500.woff")
            format("woff");
      }

      @font-face {
        font-family: "Public Sans";
        font-style: normal;
        font-display: fallback;
        font-weight: 600;
        src:
          url("https://gogaille-assets.vercel.app/fonts/public-sans-600.woff2")
            format("woff2"),
          url("https://gogaille-assets.vercel.app/fonts/public-sans-600.woff")
            format("woff");
      }

      @font-face {
        font-family: "Public Sans";
        font-style: normal;
        font-display: fallback;
        font-weight: 700;
        src:
          url("https://gogaille-assets.vercel.app/fonts/public-sans-700.woff2")
            format("woff2"),
          url("https://gogaille-assets.vercel.app/fonts/public-sans-700.woff")
            format("woff");
      }

      @font-face {
        font-family: "Right Grotesk Narrow";
        font-style: normal;
        font-display: fallback;
        font-weight: 700;
        src:
          url("https://gogaille-assets.vercel.app/fonts/right-grotesk-narrow-700.woff2")
            format("woff2"),
          url("https://gogaille-assets.vercel.app/fonts/right-grotesk-narrow-700.woff")
            format("woff");
      }
    `}</style>
  </>
);
