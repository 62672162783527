import { HTTPError, TimeoutError } from "ky";
import { HttpRetryError } from "./HttpRetryError";

export function retryBasedOnException({ nbRetry = 10 }: { nbRetry?: number }) {
  return (failureCount: number, error: unknown): boolean => {
    if (error instanceof TimeoutError) {
      if (failureCount >= nbRetry) {
        return false;
      }

      return true;
    }

    if (error instanceof HttpRetryError) {
      if (failureCount >= nbRetry) {
        return false;
      }

      return true;
    }

    if (error instanceof HTTPError) {
      if (
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/202
        error.response.status === 202 ||
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/408
        error.response.status === 408 ||
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/413
        error.response.status === 413 ||
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/429
        error.response.status === 429 ||
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status#server_error_responses
        error.response.status >= 500
      ) {
        if (failureCount >= nbRetry) {
          return false;
        }

        return true;
      }
    }

    return false;
  };
}
