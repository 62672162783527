import { createContext } from "react";
import { AVAILABLE_LOCALES } from "~/core/locale";
import { PasswordlessChannel } from "~/packages/auth0-react-passwordless/src/type";
import { AuthState, initialAuthState } from "./AuthState";

/**
 * Contains the authenticated state and authentication methods provided by the `useAuth0` hook.
 */
export interface Auth0ContextInterface extends AuthState {
  passwordlessStart(
    channel: PasswordlessChannel,
    username: string,
    locale: AVAILABLE_LOCALES,
  ): Promise<void>;

  passwordlessLogin(
    channel: PasswordlessChannel,
    username: string,
    verificationCode: string,
    locale: AVAILABLE_LOCALES,
  ): Promise<void>;

  logout(): void;
}

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <Auth0Provider>.");
};

const initialContext = {
  ...initialAuthState,
  identityNamespace: "",
  passwordlessStart: stub,
  passwordlessLogin: stub,
  logout: stub,
};

/**
 * The Auth0 Context
 */
const Auth0Context = createContext<Auth0ContextInterface>(initialContext);

export default Auth0Context;
