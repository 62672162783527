"use client";

import { createStyledBreakpointsNamespace } from "~/guidelines/Theme/mediaQueries";
import gogailleTheme from "~/guidelines/Theme/theme";

export const theme = {
  ...gogailleTheme,
  ...createStyledBreakpointsNamespace(gogailleTheme.breakpoints),
};

export const useTheme = () => {
  return theme;
};
