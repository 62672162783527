import { AriaListBoxOptions } from "@react-aria/listbox";
import { SelectState } from "@react-stately/select";
import React, { HTMLAttributes, RefObject } from "react";
import ListBox from "~/guidelines/Form/Select/ListBox/ListBox";
import { EstimatedItemHeightInPixel } from "~/guidelines/Form/Select/ListBox/ListBoxItem";
import { Popover } from "~/guidelines/Form/Select/ListBox/Popover";

const MaxItemInListBeforeScroll = 4;

export type DropdownListBoxProps<T> = {
  overlayRef?: RefObject<HTMLDivElement>;
  overlayPositionProps?: HTMLAttributes<HTMLDivElement>;
  options: AriaListBoxOptions<T>;
  state: SelectState<T>;
};

const DropdownListBox = ({
  overlayRef,
  overlayPositionProps,
  options,
  state,
}: DropdownListBoxProps<unknown>) => {
  return (
    <Popover
      overlayRef={overlayRef}
      overlayPositionProps={overlayPositionProps}
      isOpen={state.isOpen}
      onClose={state.close}
    >
      <ListBox
        options={options}
        state={state}
        maxHeight={MaxItemInListBeforeScroll * EstimatedItemHeightInPixel}
      />
    </Popover>
  );
};

export default DropdownListBox;
