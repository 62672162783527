import Cookies from "js-cookie";
import { useRouter } from "next/compat/router";
import React, { ReactNode } from "react";
import { useDestinationPageSlugs } from "~/components/Sanity/useDestinationPageSlugs";
import {
  destinationEchoppeRoute,
  destinationHomepageRoute,
  gogailleHomepageRoute,
} from "~/core/routes";
import LanguageBar from "~/localization/LocaleConflictResolver/LanguageBar";
import { useLocaleConflict } from "~/localization/LocaleConflictResolver/useLocaleConflict";

const excludedPaths = (
  destinationPages: Array<{
    seoSlug: string;
  }>,
) => {
  const paths = new Set<string>([]);

  paths.add(gogailleHomepageRoute());

  destinationPages.map((destinationPage) => {
    paths.add(destinationHomepageRoute(destinationPage.seoSlug));
    paths.add(destinationEchoppeRoute(destinationPage.seoSlug));
  });

  return paths;
};

type Props = {
  children: ReactNode;
};

const LocaleConflict = ({ children }: Props) => {
  const router = useRouter();
  const { destinationPagesSlugs } = useDestinationPageSlugs();
  const { requestLocale, isSameLocale } = useLocaleConflict();

  const localeConflict = Cookies.get("localeConflict");

  if (
    !router ||
    !destinationPagesSlugs ||
    excludedPaths(destinationPagesSlugs).has(router.asPath) ||
    isSameLocale ||
    localeConflict === "false"
  ) {
    return <>{children}</>;
  }

  return (
    <>
      <LanguageBar requestLocale={requestLocale} />
      {children}
    </>
  );
};

export default LocaleConflict;
