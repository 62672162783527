import React, { useCallback, useEffect, useReducer } from "react";
import { AVAILABLE_LOCALES } from "~/core/locale";
import { PasswordlessChannel } from "~/packages/auth0-react-passwordless/src/type";
import { useAuth0Api } from "./Api/useAuth0Api";
import Auth0Context from "./Auth0Context";
import { Auth0Token, convertAuth0IdTokenPayloadToUser } from "./Auth0Token";
import { reducer } from "./AuthReducer";
import { initialAuthState } from "./AuthState";
import { deleteToken, readToken, rejectToken, writeToken } from "./storage";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const formatTokenForDebug = (token: Auth0Token) => {
  return {
    ...token,
    idToken: JSON.stringify(token),
    expire: token.expiresAt.toUTCString(),
  };
};

/**
 * The main configuration to instantiate the `Auth0Provider`.
 */
export interface Auth0ProviderOptions {
  /**
   * Your Auth0 account domain such as `'example.auth0.com'`,
   * `'example.eu.auth0.com'` or , `'example.mycompany.com'`
   * (when using [custom domains](https://auth0.com/docs/custom-domains))
   */
  domain: string;

  /**
   * The Client ID found on your Application settings page
   */
  clientId: string;

  /**
   * The default audience to be used for requesting API access.
   */
  audience: string;

  /**
   * TODO: document that
   */
  scope: string;

  /**
   * TODO: document that
   */
  identityNamespace: string;

  onAuthenticationEvolve?: (
    event:
      | "logged-in"
      | "logged-out"
      // Authentication initialization
      | "no-authentication-in-storage"
      | "stored-token-shape-rejected"
      | "stored-token-scope-rejected"
      | "stored-token-renewal-failed"
      | "stored-token-renewed-with-success"
      | "stored-token-accepted"
      // Token renewal
      | "token-renewal-failed"
      | "token-renewed-with-success",
    who?: string,
    context?: Record<string, unknown>,
  ) => void;

  /**
   * The child nodes your Provider has wrapped
   */
  children?: React.ReactNode;
}

/**
 * ```jsx
 * <Auth0Provider
 *   domain={domain}
 *   clientId={clientId}
 *   audience={audience}>
 *   <MyApp />
 * </Auth0Provider>
 * ```
 *
 * Provides the Auth0Context to its child components.
 *
 * @TODO: Improve error handling on each step: eg `dispatch({ type: "ERROR", error: loginError(error) });`
 */
const Auth0Provider = (opts: Auth0ProviderOptions): JSX.Element => {
  const { children, onAuthenticationEvolve = noop } = opts;

  const [state, dispatch] = useReducer(reducer, { ...initialAuthState });

  const {
    passwordlessStartRequest,
    passwordlessLoginRequest,
    refreshTokenRequest,
  } = useAuth0Api();

  const passwordlessStart = useCallback(
    async (
      channel: PasswordlessChannel,
      username: string,
      locale: AVAILABLE_LOCALES,
    ): Promise<void> => {
      await passwordlessStartRequest({ channel, username }, opts, locale);
    },
    [passwordlessStartRequest, opts],
  );

  const passwordlessLogin = useCallback(
    async (
      channel: PasswordlessChannel,
      username: string,
      verificationCode: string,
      locale: AVAILABLE_LOCALES,
    ): Promise<void> => {
      const token = await passwordlessLoginRequest(
        { channel, username },
        verificationCode,
        opts,
        locale,
      );
      const user = convertAuth0IdTokenPayloadToUser(
        token.idTokenPayload,
        opts.identityNamespace,
      );

      writeToken(token);
      dispatch({ type: "PASSWORDLESS_LOGIN_COMPLETE", user });
      onAuthenticationEvolve("logged-in", username);
    },
    [onAuthenticationEvolve, opts, passwordlessLoginRequest],
  );

  const logout = useCallback(() => {
    deleteToken();
    dispatch({ type: "LOGOUT" });
    onAuthenticationEvolve("logged-out");
  }, [onAuthenticationEvolve]);

  const renewTokenIfNecessary = useCallback(
    async (someToken: Auth0Token, now: Date): Promise<Auth0Token> => {
      if (someToken.expiresAt > now) {
        return someToken;
      }

      return refreshTokenRequest(someToken.refreshToken, opts);
    },
    [refreshTokenRequest, opts],
  );

  const readAndRenewTokenFromStorage = useCallback(async (): Promise<
    Auth0Token | undefined
  > => {
    const storedTokenQuery = readToken();
    if (storedTokenQuery.result === "no-token-found") {
      dispatch({ type: "INITIALIZED", user: undefined });
      onAuthenticationEvolve("no-authentication-in-storage");
      return;
    }

    if (storedTokenQuery.result === "invalid-token-shape") {
      dispatch({ type: "INITIALIZED", user: undefined });
      onAuthenticationEvolve("stored-token-shape-rejected", undefined, {
        invalidRawToken: storedTokenQuery.invalidRawToken,
      });
      return;
    }

    const storedToken = storedTokenQuery.token;

    if (storedToken.scope !== opts.scope) {
      rejectToken(
        `Scope does not match (expected: ${JSON.stringify(
          opts.scope,
        )}, found: ${JSON.stringify(storedToken.scope)}}`,
      );
      dispatch({ type: "INITIALIZED", user: undefined });
      onAuthenticationEvolve(
        "stored-token-scope-rejected",
        convertAuth0IdTokenPayloadToUser(
          storedToken.idTokenPayload,
          opts.identityNamespace,
        ).userId,
        { rejectedStoredToken: formatTokenForDebug(storedToken) },
      );
      return;
    }

    let renewedToken: Auth0Token | undefined = undefined;
    try {
      renewedToken = await renewTokenIfNecessary(storedToken, new Date());
      writeToken(renewedToken);

      if (renewedToken.expiresAt !== storedToken.expiresAt) {
        onAuthenticationEvolve(
          "stored-token-renewed-with-success",
          convertAuth0IdTokenPayloadToUser(
            renewedToken.idTokenPayload,
            opts.identityNamespace,
          ).userId,
          {
            storedToken: formatTokenForDebug(storedToken),
            renewedToken: formatTokenForDebug(renewedToken),
          },
        );
      } else {
        onAuthenticationEvolve(
          "stored-token-accepted",
          convertAuth0IdTokenPayloadToUser(
            storedToken.idTokenPayload,
            opts.identityNamespace,
          ).userId,
          {
            storedToken: formatTokenForDebug(storedToken),
          },
        );
      }
    } catch (error) {
      renewedToken = undefined;
      rejectToken("Fail to renew the stored token");
      onAuthenticationEvolve(
        "stored-token-renewal-failed",
        convertAuth0IdTokenPayloadToUser(
          storedToken.idTokenPayload,
          opts.identityNamespace,
        ).userId,
        { rejectedStoredToken: formatTokenForDebug(storedToken) },
      );
    } finally {
      dispatch({
        type: "INITIALIZED",
        user:
          renewedToken !== undefined
            ? convertAuth0IdTokenPayloadToUser(
                renewedToken.idTokenPayload,
                opts.identityNamespace,
              )
            : undefined,
      });
    }

    return renewedToken;
  }, [
    onAuthenticationEvolve,
    opts.identityNamespace,
    opts.scope,
    renewTokenIfNecessary,
  ]);

  useEffect(() => {
    if (!opts.domain) {
      throw new Error("The domain props is missing or empty");
    }

    if (!opts.clientId) {
      throw new Error("The clientId props is missing or empty");
    }

    if (!opts.audience) {
      throw new Error("The audience props is missing or empty");
    }

    if (!opts.scope) {
      throw new Error("The scope props is missing or empty");
    }
  }, [opts.audience, opts.clientId, opts.domain, opts.scope]);

  useEffect(() => {
    (async () => {
      if (!state.isInitialized) {
        readAndRenewTokenFromStorage();
      }
    })();
  }, [readAndRenewTokenFromStorage, state.isInitialized]);

  return (
    <Auth0Context.Provider
      value={{
        ...state,
        passwordlessStart,
        passwordlessLogin,
        logout,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export default Auth0Provider;
