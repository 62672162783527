import * as z from "zod";

const UserIdSchema = z.string();

export const Auth0IdTokenPayloadSchema = z
  .object({
    name: z.string(),
    email: z.string(),
    email_verified: z.boolean(),
  })
  .catchall(z.unknown());

export const Auth0TokenSchema = z.object({
  accessToken: z.string(),
  refreshToken: z.string(),
  idTokenPayload: Auth0IdTokenPayloadSchema,
  tokenType: z.string(),
  scope: z.string(),
  expiresAt: z.date(),
});

export type Auth0Token = z.infer<typeof Auth0TokenSchema>;

export type Auth0IdTokenPayload = z.infer<typeof Auth0IdTokenPayloadSchema>;

export function extractUserIdFromIdToken(
  token: Auth0IdTokenPayload,
  identityNamespace: string,
): string {
  const userId = token[`${identityNamespace}/user_id`];
  if (!userId) {
    throw new Error(
      `Unable to extract the user id from the id token with the identity namespace: "${identityNamespace}". Available keys: "${Object.keys(
        token,
      ).join(", ")}".`,
    );
  }

  return UserIdSchema.parse(userId);
}

export interface User {
  userId: string;
  name: string;
  email: string;
  emailIsVerified: boolean;
}

export const convertAuth0IdTokenPayloadToUser = (
  auth0IdTokenPayload: Auth0IdTokenPayload,
  identityNamespace: string,
): User => ({
  userId: extractUserIdFromIdToken(auth0IdTokenPayload, identityNamespace),
  email: auth0IdTokenPayload.email,
  emailIsVerified: auth0IdTokenPayload.email_verified,
  name: auth0IdTokenPayload.name,
});
