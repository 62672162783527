import { z } from "zod";

export enum ResourceVisibility {
  "PRIVATE" = "private",
  "PUBLIC" = "public",
}

export const ALL_RESOURCE_VISIBILITY = [
  ResourceVisibility.PRIVATE,
  ResourceVisibility.PUBLIC,
];

export const ResourceVisibilityPayloadSchema = z.object({
  value: z.nativeEnum(ResourceVisibility),
});
