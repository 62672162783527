import addDays from "date-fns/addDays";
import React, { useCallback, useEffect, useReducer } from "react";
import { randomShortId } from "~/core/random";
import {
  deleteToken,
  writeToken,
} from "~/packages/auth0-react-passwordless/src/storage";
import Auth0Context from "./Auth0Context";
import { Auth0Token, convertAuth0IdTokenPayloadToUser } from "./Auth0Token";
import { reducer } from "./AuthReducer";
import { initialAuthState } from "./AuthState";

type FakeUser = {
  userId: string;
  name: string;
  email: string;
};

/**
 * The main configuration to instantiate the `Auth0Provider`.
 */
export interface Auth0ProviderOptions {
  identityNamespace?: string;
  isAuthenticated?: boolean;
  user?: FakeUser;
  children?: React.ReactNode;
}

const generateFakeToken = (
  {
    userId = `fake-user-id-${randomShortId()}`,
    name = "John Do",
    email = "jonh@do.com",
  }: Partial<FakeUser>,
  identityNamespace: string = "",
): Auth0Token => {
  const today = new Date();

  return {
    accessToken: `fake-access-token-${randomShortId()}`,
    refreshToken: `fake-refresh-token-${randomShortId()}`,
    idTokenPayload: {
      [`${identityNamespace}/user_id`]: userId,
      name,
      email,
      email_verified: true,
    },
    tokenType: "fake-token",
    scope: "openid profile email offline_access",
    expiresAt: addDays(today, 1),
  };
};

const FakeAuth0Provider = (opts: Auth0ProviderOptions): JSX.Element => {
  const { children, isAuthenticated = false, user = {} } = opts;

  const [state, dispatch] = useReducer(reducer, { ...initialAuthState });

  const passwordlessStart = useCallback(() => {
    return Promise.resolve();
  }, []);

  const passwordlessLogin = useCallback(
    async (email: string): Promise<void> => {
      const fakeToken = generateFakeToken({ email }, opts.identityNamespace);

      dispatch({
        type: "PASSWORDLESS_LOGIN_COMPLETE",
        user: convertAuth0IdTokenPayloadToUser(
          fakeToken.idTokenPayload,
          opts.identityNamespace ?? "",
        ),
      });
    },
    [opts.identityNamespace],
  );

  const logout = useCallback(() => {
    dispatch({ type: "LOGOUT" });
    deleteToken();
  }, []);

  useEffect(
    () => {
      if (isAuthenticated) {
        const fakeToken = generateFakeToken(user, opts.identityNamespace);

        dispatch({
          type: "INITIALIZED",
          user: convertAuth0IdTokenPayloadToUser(
            fakeToken.idTokenPayload,
            opts.identityNamespace ?? "",
          ),
        });

        writeToken(fakeToken);
      } else {
        dispatch({ type: "INITIALIZED", user: undefined });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Auth0Context.Provider
      value={{
        ...state,
        passwordlessStart,
        passwordlessLogin,
        logout,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export default FakeAuth0Provider;
