import { User } from "./Auth0Token";
import { AuthState } from "./AuthState";

type Action =
  | {
      type: "INITIALIZED" | "PASSWORDLESS_LOGIN_COMPLETE" | "TOKEN_REFRESHED";
      user: User | undefined;
    }
  | { type: "LOGOUT" }
  | { type: "ERROR"; error: Error };

/**
 * Handles how that state changes in the `useAuth0` hook.
 */
export const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case "INITIALIZED":
      return {
        ...state,
        isAuthenticated: !!action.user,
        user: action.user,
        isLoading: false,
        isInitialized: true,
        error: undefined,
      };

    case "TOKEN_REFRESHED":
    case "PASSWORDLESS_LOGIN_COMPLETE":
      return {
        ...state,
        isAuthenticated: !!action.user,
        user: action.user,
      };

    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        error: undefined,
        user: undefined,
      };

    case "ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
  }
};
