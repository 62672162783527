import { match } from "@formatjs/intl-localematcher";
import Negotiator from "negotiator";

export enum AVAILABLE_LOCALES {
  en = "en",
  fr = "fr",
}

export enum AVAILABLE_ISO15897_LOCALES {
  en_GB = "en_GB",
  fr_FR = "fr_FR",
}

export enum AVAILABLE_BCP47_LOCALES {
  en_GB = "en-GB",
  fr_FR = "fr-FR",
}

export const DEFAULT_LOCALE = AVAILABLE_LOCALES.en;

export function isAcceptedLocale(locale: unknown): locale is AVAILABLE_LOCALES {
  if (typeof locale !== "string") {
    return false;
  }

  return locale in AVAILABLE_LOCALES;
}

export function guardAvailableLocale(
  locale: unknown,
): asserts locale is AVAILABLE_LOCALES {
  if (!isAcceptedLocale(locale)) {
    throw new Error(`Unavailable locale "${locale}"`);
  }
}

export function selectFirstAcceptedLocale(
  ...mayBeLocales: Array<unknown>
): AVAILABLE_LOCALES {
  for (const mayBeLocale of mayBeLocales) {
    if (isAcceptedLocale(mayBeLocale)) {
      return mayBeLocale;
    }
  }

  return DEFAULT_LOCALE;
}

const ISO_15897_VALIDATION_REGEX = /[a-z]{2}_[A-Z]{2}/;

export const guardIso15897LocaleFormat = (locale: unknown): void => {
  if (typeof locale !== "string") {
    throw new Error(`Wrong type. Expected string, given "${typeof locale}"`);
  }

  if (!locale.match(ISO_15897_VALIDATION_REGEX)) {
    throw new Error(
      `Invalid locale format "${locale}". Should be in the ISO 15897 format: "ab_CD".`,
    );
  }
};

export const convertIso15897LocaleToBcp47Locale = (
  locale: AVAILABLE_ISO15897_LOCALES,
): string => {
  guardIso15897LocaleFormat(locale);

  return locale.replace("_", "-");
};

export const extractLanguageFromIso15897Locale = (locale: string): string => {
  guardIso15897LocaleFormat(locale);

  return locale.split("_")[0];
};

export const expandLocaleToIso15897Locale = (
  locale: AVAILABLE_LOCALES,
): AVAILABLE_ISO15897_LOCALES => {
  switch (locale) {
    case AVAILABLE_LOCALES.en:
      return AVAILABLE_ISO15897_LOCALES.en_GB;

    case AVAILABLE_LOCALES.fr:
      return AVAILABLE_ISO15897_LOCALES.fr_FR;

    default:
      // Should never happen
      throw new Error(
        `Unable to expand the locale "${locale}": no mapping available`,
      );
  }
};

export const convertIso15897LocaleToLocale = (
  locale: AVAILABLE_ISO15897_LOCALES,
): AVAILABLE_LOCALES => {
  switch (locale) {
    case AVAILABLE_ISO15897_LOCALES.en_GB:
      return AVAILABLE_LOCALES.en;

    case AVAILABLE_ISO15897_LOCALES.fr_FR:
      return AVAILABLE_LOCALES.fr;

    default:
      // Should never happen
      throw new Error(
        `Unable to convert the locale "${locale}": no mapping available`,
      );
  }
};

export const convertBcp47LocaleToLocale = (
  locale: AVAILABLE_BCP47_LOCALES,
): AVAILABLE_LOCALES => {
  switch (locale) {
    case AVAILABLE_BCP47_LOCALES.en_GB:
      return AVAILABLE_LOCALES.en;

    case AVAILABLE_BCP47_LOCALES.fr_FR:
      return AVAILABLE_LOCALES.fr;

    default:
      // Should never happen
      throw new Error(
        `Unable to convert the locale "${locale}": no mapping available`,
      );
  }
};

function convertLocaleToLanguageCode(locale: string): AVAILABLE_LOCALES {
  if (locale.includes("-")) {
    return convertBcp47LocaleToLocale(locale as AVAILABLE_BCP47_LOCALES);
  }

  if (locale.includes("_")) {
    return convertIso15897LocaleToLocale(locale as AVAILABLE_ISO15897_LOCALES);
  }

  return locale as AVAILABLE_LOCALES;
}

export const processUserLocale = ({
  acceptLanguage,
  defaultLocale,
}: {
  acceptLanguage: string;
  defaultLocale: string;
}): AVAILABLE_LOCALES => {
  const headers = { "accept-language": acceptLanguage };
  const languages = new Negotiator({ headers }).languages();
  const locales = Object.values(AVAILABLE_LOCALES);

  return convertLocaleToLanguageCode(match(languages, locales, defaultLocale));
};
