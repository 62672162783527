import Image from "next/image";
import React, { useEffect, useState } from "react";
import { AVAILABLE_LOCALES } from "~/core/locale";

const flagRatio = 3 / 2;
const flagWidth = 24;
const flagHeight = flagWidth / flagRatio;

const hasKnownFlag = async (countryCode: string): Promise<boolean> => {
  if (["FR", "GB"].includes(countryCode)) {
    return true;
  }

  const { hasFlag } = await import("country-flag-icons");

  return hasFlag(countryCode);
};

export function CountryFlag({ countryCode }: { countryCode: string }) {
  const [hasFlag, setHasFlag] = useState(false);

  useEffect(() => {
    hasKnownFlag(countryCode).then(setHasFlag);
  }, [countryCode]);

  if (!hasFlag) {
    return null;
  }

  return (
    <Image
      src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode.toUpperCase()}.svg`}
      width={flagWidth}
      height={flagHeight}
      alt={`${countryCode.toUpperCase()} country flag`}
      aria-hidden="true"
    />
  );
}

const defaultCountryCodeForLocale: Record<AVAILABLE_LOCALES, string> = {
  [AVAILABLE_LOCALES.fr]: "FR",
  [AVAILABLE_LOCALES.en]: "GB",
};

export function CountryFlagOfLocale({ locale }: { locale: AVAILABLE_LOCALES }) {
  const countryCode = defaultCountryCodeForLocale[locale];

  if (countryCode === undefined) {
    return null;
  }

  return <CountryFlag countryCode={countryCode} />;
}
