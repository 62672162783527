import { User } from "~/packages/auth0-react-passwordless/src/Auth0Token";

export interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  error?: Error;
  user?: User;
}

export const initialAuthState: Omit<AuthState, "identityNamespace"> = {
  isInitialized: false,
  isAuthenticated: false,
  isLoading: true,
};
