import { useMutation } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { UserLocaleChangeResult } from "~/applications/Checkout/Domain/UserLocaleChangeResult";
import { gogailleAuthenticatedApiClient } from "~/clients/GogailleApiClient";
import { parseViolationsPayload } from "~/core/Violation";
import { ViolationsSchema } from "~/core/api/commons";

type PutUserLocaleCommand = {
  userId: string;
  newLocale: string;
};

const putUserLocale = async ({
  userId,
  newLocale,
}: PutUserLocaleCommand): Promise<UserLocaleChangeResult> => {
  try {
    await gogailleAuthenticatedApiClient.put(
      `${process.env.GOGAILLE_API_ENDPOINT}/api/user-profile/user-settings/${userId}/locale`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          locale: newLocale,
        }),
      },
    );
  } catch (err) {
    if (err instanceof HTTPError && err.response.status === 422) {
      const violationsPayload = ViolationsSchema.parse(
        await err.response.json(),
      );

      return {
        success: false,
        violations: parseViolationsPayload(violationsPayload),
      };
    }

    throw err;
  }

  return {
    success: true,
  };
};

const usePutUserLocale = (): typeof putUserLocale => {
  const { mutateAsync: runPutUserLocale } = useMutation(putUserLocale);

  return runPutUserLocale;
};

export default usePutUserLocale;
