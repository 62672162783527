import { useButton } from "@react-aria/button";
import { HiddenSelect, useSelect } from "@react-aria/select";
import {
  Item as BaseItem,
  Section as BaseSection,
} from "@react-stately/collections";
import { useSelectState } from "@react-stately/select";
import { AriaSelectProps } from "@react-types/select";
import { ItemProps, Node, SectionProps } from "@react-types/shared";
import React, { ReactNode, RefObject, useEffect, useRef } from "react";
import { useOverlayPosition } from "react-aria";
import { useIsomorphicLayoutEffect } from "react-use";
import styled, { css } from "styled-components";
import { useModalController } from "~/core/modal";
import usePrevious from "~/core/usePrevious";
import { FocusRing } from "~/guidelines/Focus";
import BottomSheetListBox from "~/guidelines/Form/Select/ListBox/BottomSheetListBox";
import DropdownListBox from "~/guidelines/Form/Select/ListBox/DropdownListBox";
import { NavigationExpandLess, NavigationExpandMore } from "~/guidelines/Icon";
import mediaQueries, {
  useIsMobileDevice,
} from "~/guidelines/Theme/mediaQueries";
import { useTheme } from "~/guidelines/Theme/useTheme";
import { body1, caption1, heading7 } from "~/guidelines/Typography";

export const SelectContainer = styled.div`
  position: relative;
`;

export const SelectButton = styled.button<{ isOpen: boolean }>`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme, disabled }) =>
    disabled ? theme.color.line.greySeparator : theme.color.background.white};
  border: 1px solid ${({ theme }) => theme.color.line.greyStroke};
  outline-color: ${({ theme }) => theme.color.brand.blue};
  box-sizing: border-box;
  border-radius: 4px;

  ${body1};

  ${({ isOpen, theme }) =>
    isOpen
      ? css`
          ${FloatingLabel} {
            transform: scale(1) translate3d(0, -16px, 0);
            ${caption1};
            color: ${theme.color.text.greyDark};
          }
        `
      : undefined}
`;

const SelectedValue = styled.div`
  ${heading7};
  color: ${({ theme }) => theme.color.text.blueDark};
`;

const FloatingContainer = styled.div`
  ${body1};
  display: flex;
  position: relative;
  transition: all 0.2s ease-out;
  width: 100%;
  padding: 21px 16px 3px;
`;

const FloatingLabel = styled.span<{
  hasSelectedValue: boolean;
}>`
  ${body1};
  color: ${({ theme }) => theme.color.text.greyDark};
  transition: all 0.2s ease-out;
  position: absolute;
  transform: translate(0px, -8px) scale(1);
  transform-origin: top left;

  ${({ hasSelectedValue, theme }) =>
    hasSelectedValue
      ? css`
          transform: scale(1) translate3d(0, -16px, 0);
          ${caption1};
          color: ${theme.color.text.greyDark};
        `
      : undefined};
`;

const FloatingValue = styled.div`
  display: block;
  height: 1.3em;
  overflow: hidden;
  text-align: left;
`;

const Label = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  text-align: start;
  gap: 8px;
  flex-grow: 1;
  padding: 15px ${({ theme }) => theme.spacing(4)};

  ${body1};
  color: ${({ theme }) => theme.color.text.greyDark};

  ${mediaQueries.md()} {
    padding: 15px ${({ theme }) => theme.spacing(5)};
  }

  ${mediaQueries.xxsOnly()} {
    padding: 15px ${({ theme }) => theme.spacing(3)};
  }
`;

type SelectProps<T> = AriaSelectProps<T> & {
  useFloatingLabel?: boolean;
  renderSelectedItem?: (item: Node<unknown>) => ReactNode;
};

const Select = React.forwardRef<HTMLElement, SelectProps<unknown>>(
  function SelectWithForwardedRef(
    { useFloatingLabel = true, renderSelectedItem = undefined, ...props },
    forwardedRef,
  ) {
    const theme = useTheme();
    const isMobile = useIsMobileDevice();

    const state = useSelectState(props as any);

    const defaultTriggerRef = useRef<HTMLButtonElement>();
    const triggerRef = (forwardedRef ??
      defaultTriggerRef) as RefObject<HTMLButtonElement>;

    const overlayRef = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;

    const BottomSheetListBoxController = useModalController(BottomSheetListBox);

    const { triggerProps, valueProps, menuProps } = useSelect<unknown>(
      props,
      state,
      triggerRef,
    );

    const { buttonProps } = useButton(
      {
        ...triggerProps,
        id: `${props.id}-button`,
        "aria-labelledby": `${props.id}-button-name`,
        "aria-describedby": `${props.id}-button-description`,
      },
      triggerRef,
    );

    const { overlayProps: overlayPositionProps, updatePosition } =
      useOverlayPosition({
        targetRef: triggerRef,
        overlayRef: overlayRef,
        placement: "bottom",
        containerPadding: 0,
        offset: 0,
        crossOffset: 0,
        shouldFlip: true,
        isOpen: state.isOpen,
        onClose: state.close,
      });

    /**
     * Update position once the ListBox has rendered. This ensures that
     * it flips properly when it doesn't fit in the available space.
     *
     * @TODO Wait for react-aria to add a ResizeObserver in useOverlayPosition so we don't need this.
     */
    useIsomorphicLayoutEffect(() => {
      if (state.isOpen) {
        requestAnimationFrame(() => {
          updatePosition();
        });
      }
    }, [state.isOpen, updatePosition]);

    const toggleOpen = () => {
      const isOpen = !state.isOpen;

      state.setOpen(isOpen);
      if (props.onOpenChange) {
        props.onOpenChange(isOpen);
      }
      if (isMobile) {
        if (state.isOpen) {
          BottomSheetListBoxController.show({
            options: menuProps,
            state: state,
            title: props.label ?? props["aria-label"],
          });
        } else {
          BottomSheetListBoxController.hide();
        }
      }
    };

    const previousSelectedKey = usePrevious(state.selectedKey);

    useEffect(() => {
      if (isMobile && previousSelectedKey !== undefined) {
        if (state.selectedKey !== previousSelectedKey) {
          BottomSheetListBoxController.hide();
        }
      }
    }, [
      BottomSheetListBoxController,
      isMobile,
      previousSelectedKey,
      state.selectedKey,
    ]);

    const hasSelectedValue =
      state.selectedKey !== null &&
      state.selectedKey !== "" &&
      state.selectedKey !== undefined;

    return (
      <SelectContainer>
        <HiddenSelect
          state={state}
          triggerRef={triggerRef}
          label={props.label}
          name={props.name}
          isDisabled={props.isDisabled}
        />
        <FocusRing>
          <SelectButton
            {...buttonProps}
            ref={triggerRef}
            disabled={props.isDisabled}
            isOpen={state.isOpen}
            onClick={isMobile ? toggleOpen : buttonProps.onClick}
          >
            {useFloatingLabel && !renderSelectedItem ? (
              <FloatingContainer>
                <FloatingLabel
                  {...valueProps}
                  hasSelectedValue={hasSelectedValue}
                >
                  {props.label}
                </FloatingLabel>
                <FloatingValue>
                  <SelectedValue>
                    {state.selectedItem ? state.selectedItem.rendered : ` `}
                  </SelectedValue>
                </FloatingValue>
              </FloatingContainer>
            ) : (
              <Label>
                {state.selectedItem ? (
                  <SelectedValue>
                    {renderSelectedItem
                      ? renderSelectedItem(state.selectedItem)
                      : state.selectedItem.rendered}
                  </SelectedValue>
                ) : (
                  props.label
                )}
              </Label>
            )}
            <span aria-hidden="true" style={{ display: "flex" }}>
              {state.isOpen ? (
                <NavigationExpandLess color={theme.color.brand.red} />
              ) : (
                <NavigationExpandMore color={theme.color.brand.red} />
              )}
            </span>
          </SelectButton>
        </FocusRing>

        {isMobile ? undefined : (
          <DropdownListBox
            overlayRef={overlayRef}
            overlayPositionProps={overlayPositionProps}
            options={menuProps}
            state={state}
          />
        )}
      </SelectContainer>
    );
  },
);

const Section = BaseSection as <T>(
  props: SectionProps<T> & { key: string },
) => JSX.Element;

const Item = BaseItem as <T>(
  props: ItemProps<T> & { key: string },
) => JSX.Element;

export { Select, Item, Section };
