import { useCallback } from "react";
import * as z from "zod";
import { AVAILABLE_LOCALES } from "~/core/locale";
import { PasswordlessQuery } from "~/packages/auth0-react-passwordless/src/type";
import { Auth0Token } from "../Auth0Token";
import * as Auth0Api from "./Auth0Api";

export const Auth0PostOauthTokenPayloadParser = z.object({
  access_token: z.string(),
  refresh_token: z.string(),
  id_token: z.string(),
  token_type: z.string(),
  scope: z.string(),
  expires_in: z.number(),
});

export type Auth0PostOauthTokenPayload = z.infer<
  typeof Auth0PostOauthTokenPayloadParser
>;

type Auth0ApiHook = {
  passwordlessStartRequest(
    passwordlessQuery: PasswordlessQuery,
    options: Auth0Api.RequestOptions,
    locale: AVAILABLE_LOCALES,
  ): Promise<void>;
  passwordlessLoginRequest(
    passwordlessQuery: PasswordlessQuery,
    verificationCode: string,
    options: Auth0Api.RequestOptions,
    locale: AVAILABLE_LOCALES,
  ): Promise<Auth0Token>;
  refreshTokenRequest(
    refreshToken: string,
    options: Auth0Api.RequestOptions,
    abortController?: AbortController,
  ): Promise<Auth0Token>;
};

export const useAuth0Api = (): Auth0ApiHook => {
  const passwordlessStartRequest = useCallback(
    async (
      passwordlessQuery: PasswordlessQuery,
      options: Auth0Api.RequestOptions,
      locale: AVAILABLE_LOCALES,
    ): Promise<void> => {
      return Auth0Api.passwordlessStartRequest(
        passwordlessQuery,
        options,
        locale,
      );
    },
    [],
  );

  const passwordlessLoginRequest = useCallback(
    async (
      passwordlessQuery: PasswordlessQuery,
      verificationCode: string,
      options: Auth0Api.RequestOptions,
      locale: AVAILABLE_LOCALES,
    ): Promise<Auth0Token> => {
      return Auth0Api.passwordlessLoginRequest(
        passwordlessQuery,
        verificationCode,
        options,
        locale,
      );
    },
    [],
  );

  const refreshTokenRequest = useCallback(
    async (
      refreshToken: string,
      options: Auth0Api.RequestOptions,
      abortController?: AbortController,
    ): Promise<Auth0Token> => {
      return Auth0Api.refreshTokenRequest(
        refreshToken,
        options,
        abortController,
      );
    },
    [],
  );

  return {
    passwordlessStartRequest,
    passwordlessLoginRequest,
    refreshTokenRequest,
  };
};
