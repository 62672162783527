import { addBreadcrumb } from "@sentry/nextjs";
import { options } from "~/clients/Auth0";
import { refreshTokenRequest } from "~/packages/auth0-react-passwordless/src/Api/Auth0Api";
import { Auth0Token } from "~/packages/auth0-react-passwordless/src/Auth0Token";
import {
  readToken,
  rejectToken,
  writeToken,
} from "~/packages/auth0-react-passwordless/src/storage";
import { NoAuthenticationInStorage } from "~/packages/passwordless/NoAuthenticationInStorage";

const renewTokenIfNecessary = async (
  someToken: Auth0Token,
  now: Date,
): Promise<Auth0Token> => {
  if (someToken.expiresAt > now) {
    return someToken;
  }

  return refreshTokenRequest(someToken.refreshToken, options);
};

const initializeTokenFromStorage = async () => {
  const storedTokenQuery = readToken();

  if (storedTokenQuery.result === "no-token-found") {
    addBreadcrumb({
      message: "no-authentication-in-storage",
      data: {
        ...storedTokenQuery,
        cookieEnabled: navigator.cookieEnabled,
      },
      level: "debug",
    });
    throw new NoAuthenticationInStorage();
  }

  if (storedTokenQuery.result === "invalid-token-shape") {
    throw new Error("stored-token-shape-rejected");
  }

  const storedToken = storedTokenQuery.token;

  if (storedToken.scope !== options.scope) {
    rejectToken(
      `Scope does not match (expected: ${JSON.stringify(
        options.scope,
      )}, found: ${JSON.stringify(storedToken.scope)}}`,
    );
  }

  let renewedToken: Auth0Token | undefined = undefined;
  try {
    renewedToken = await renewTokenIfNecessary(storedToken, new Date());
    writeToken(renewedToken);
  } catch (error) {
    renewedToken = undefined;
    console.warn(`Fail to renew the stored token: ${JSON.stringify(error)}`);
  }

  return renewedToken;
};

export const readUserAuthToken = async () => {
  const token = await initializeTokenFromStorage();
  if (!token) {
    return undefined;
  }

  return { accessToken: token.accessToken };
};
