import React, { RefObject, useCallback, useEffect } from "react";
import { useIsSSR } from "react-aria";
import { useDialog, useModal, useOverlay } from "react-aria";
import Sheet from "react-modal-sheet";
import { SheetDetent } from "react-modal-sheet/dist/types";
import styled from "styled-components";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { DrawerBackgroundColor } from "~/guidelines/Drawer/DrawerOptions";

// Let's only expose the props we really use.
export type BottomSheetProps = {
  isOpen: boolean;
  isDismissable: boolean;
  header?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
  primaryAction?: React.ReactNode;
  secondaryAction?: React.ReactNode;
  onAfterClose: () => void;
  snapPoints?: number[] | undefined;
  backgroundColor?: DrawerBackgroundColor;
  onClose: () => void;
  headerColor?: string;
  detent?: SheetDetent;
  closeButtonRef?: RefObject<HTMLButtonElement>;
};

const SheetBackdrop = styled(Sheet.Backdrop)`
  pointer-events: none !important;
`;

const SheetContainer = styled(Sheet.Container)`
  overflow-x: hidden;
`;

const SheetHeader = styled(Sheet.Header)<{ $color: string }>`
  background-color: ${({ $color }) => $color};

  & > .react-modal-sheet-header {
    height: auto !important;
    padding: ${({ theme }) => theme.spacing(4, 0)};
  }
`;

const SheetContent = styled(Sheet.Content)``;

const BottomSheetFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

const BottomSheetActions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

const BottomSheet = ({
  isOpen,
  isDismissable,
  onClose,
  onAfterClose,
  headerColor = "#ffffff",
  detent = "content-height",
  closeButtonRef,
  ...props
}: BottomSheetProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const dialog = useDialog({}, ref);
  const overlay = useOverlay(
    { onClose: onClose, isOpen: isOpen, isDismissable: isDismissable },
    ref,
  );
  useModal();

  const isSSR = useIsSSR();
  const { header, children, footer, primaryAction, secondaryAction } = props;

  const isKeyboardOpen = useDetectKeyboardOpen();
  const keyboardHeight = isSSR
    ? 0
    : (isKeyboardOpen &&
        window.innerHeight - (window?.visualViewport?.height ?? 0) + "px") ||
      0;

  const onCloseSheet = useCallback(() => {
    onClose();
    onAfterClose();
  }, [onAfterClose, onClose]);

  useEffect(() => {
    if (closeButtonRef && closeButtonRef.current) {
      const closeButton = closeButtonRef.current;
      closeButton.addEventListener("click", onCloseSheet);

      return () => {
        closeButton.removeEventListener("click", onCloseSheet);
      };
    }
  }, [closeButtonRef, onCloseSheet]);

  return (
    <Sheet
      isOpen={isOpen}
      onClose={onCloseSheet}
      data-testid="bottom-sheet"
      detent={detent}
      draggable
    >
      <SheetBackdrop onTap={onClose} />
      <SheetContainer
        {...overlay.overlayProps}
        {...(dialog.dialogProps as any)}
        ref={ref}
      >
        <SheetHeader $color={headerColor}>
          <SheetHeader $color={headerColor} />
          {header}
        </SheetHeader>
        <SheetContent
          disableDrag
          style={{
            paddingBottom: keyboardHeight || "0px",
            transition: "padding 200ms",
          }}
        >
          {children}
        </SheetContent>
        <BottomSheetFooter>
          {primaryAction || secondaryAction ? (
            <BottomSheetActions>
              {primaryAction}
              {secondaryAction}
            </BottomSheetActions>
          ) : (
            footer
          )}
        </BottomSheetFooter>
      </SheetContainer>
    </Sheet>
  );
};

export default BottomSheet;
