import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { AriaListBoxOptions } from "@react-aria/listbox";
import { SelectState } from "@react-stately/select";
import React, { ReactNode } from "react";
import styled from "styled-components";
import BottomSheet from "~/guidelines/Drawer/BottomSheet";
import ListBox from "~/guidelines/Form/Select/ListBox/ListBox";
import { heading5 } from "~/guidelines/Typography";

const Label = styled.div`
  padding: ${({ theme }) => theme.spacing(5, 6)};
  ${heading5};
`;

export type BottomSheetListBoxProps<T> = {
  options: AriaListBoxOptions<T>;
  state: SelectState<T>;
  title: ReactNode | string;
};

const BottomSheetListBox = NiceModal.create<BottomSheetListBoxProps<unknown>>(
  ({ options, state, title }) => {
    const modal = useModal();

    return (
      <BottomSheet
        isDismissable
        isOpen={modal.visible}
        onClose={modal.hide}
        onAfterClose={() => {
          if (!modal.keepMounted) {
            modal.remove();
          }
        }}
        header={<Label>{title}</Label>}
      >
        <ListBox options={options} state={state} />
      </BottomSheet>
    );
  },
);

export default BottomSheetListBox;
