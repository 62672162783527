import { z } from "zod";

export const PricePayloadSchema = z.object({
  amount: z.string(),
  currency: z.string(),
});

export const ViolationSchema = z.object({
  code: z.string().nullable(),
  message: z.string(),
  propertyPath: z.string(),
});

export const ViolationsSchema = z.object({
  violations: z.array(ViolationSchema),
});
