import { useIsSSR } from "@react-aria/ssr";
import { createTheme, down, up } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { Breakpoint, Orientation } from "~/guidelines/Theme/Breakpoints";

export const createStyledBreakpointsNamespace = createTheme;

const mediaQueries = {
  xxsOnly: (orientation?: Orientation) => down(Breakpoint.xs, orientation),

  xs: (orientation?: Orientation) => up(Breakpoint.xs, orientation),
  sm: (orientation?: Orientation) => up(Breakpoint.sm, orientation),
  md: (orientation?: Orientation) => up(Breakpoint.md, orientation),
  lg: (orientation?: Orientation) => up(Breakpoint.lg, orientation),
  xl: (orientation?: Orientation) => up(Breakpoint.xl, orientation),
  xxl: (orientation?: Orientation) => up(Breakpoint.xxl, orientation),
};

export default mediaQueries;

export const useIsSmallerThanMdScreen = (): boolean => {
  const isMdScreen = useBreakpoint(down("md"));

  return isMdScreen ?? false;
};

export const useIsMdScreen = (): boolean => {
  const isMdScreen = useBreakpoint(up("md"));

  return isMdScreen ?? false;
};

export const useIsLgScreen = (): boolean => {
  const isLgScreen = useBreakpoint(up("lg"));

  return isLgScreen ?? false;
};

export function useIsMobileDevice(): boolean {
  const isSSR = useIsSSR();
  const isSmallerThanMdScreen = useIsSmallerThanMdScreen();

  if (isSSR || typeof window === "undefined") {
    return false;
  }

  return isSmallerThanMdScreen;
}
