import { addBreadcrumb as sentryAddBreadcrumb } from "@sentry/nextjs";
import ky, { HTTPError, NormalizedOptions } from "ky";
import { rejectToken } from "~/packages/auth0-react-passwordless/src/storage";
import { NoAuthenticationInStorage } from "~/packages/passwordless/NoAuthenticationInStorage";
import { readUserAuthToken } from "~/packages/passwordless/auth";

const gogailleApiEndpoint = process.env.GOGAILLE_API_ENDPOINT;
if (!gogailleApiEndpoint) {
  throw new Error(
    "The environment variable 'GOGAILLE_API_ENDPOINT' is missing",
  );
}

export const gogailleApiClient = ky.create({
  // We delegate to react-query the retry logic
  retry: 0,

  hooks: {
    beforeRequest: [],

    beforeError: [
      (error) => {
        sentryAddBreadcrumb({
          type: "http",
          category: "network",
          data: {
            // Request
            method: error.request.method,
            url: error.request.url,
            headers: Object.fromEntries(error.request.headers.entries()),
            body: error.request.body,

            // Response
            status_code: error.response.status,
            reason: error.response.statusText,
            apiGwRequestId: error.response.headers.get("apigw-requestid"),
          },
          level: "error",
        });

        return error;
      },
    ],

    afterResponse: [
      (request: Request, options: NormalizedOptions, response: Response) => {
        sentryAddBreadcrumb({
          type: "http",
          category: "network",
          data: {
            // Request
            method: request.method,
            url: request.url,
            headers: Object.fromEntries(request.headers.entries()),
            body: request.body,

            // Response
            status_code: response.status,
            reason: response.statusText,
            apiGwRequestId: response.headers.get("apigw-requestid"),
          },
          level: "info",
        });

        return response;
      },

      (request: Request, options: NormalizedOptions, response: Response) => {
        // Transform 202 into error to allow retry at higher level
        if (response.status === 202) {
          throw new HTTPError(response, request, options);
        }

        return response;
      },
    ],
  },
});

export const gogailleAuthenticatedApiClient = gogailleApiClient.extend({
  hooks: {
    beforeRequest: [
      async (request: Request) => {
        try {
          const token = await readUserAuthToken();
          if (token) {
            request.headers.set(
              "authorization",
              `Bearer ${token?.accessToken}`,
            );
          }

          return request;
        } catch (error) {
          if (error instanceof NoAuthenticationInStorage) {
            rejectToken("No authentication in storage");
            document.location.reload();
          }

          throw error;
        }
      },
    ],
  },
});
