import { addBreadcrumb } from "@sentry/nextjs";
import { addDays, parseISO } from "date-fns";
import Cookie, { CookieAttributes } from "js-cookie";
import * as z from "zod";
import { Auth0IdTokenPayloadSchema, Auth0Token } from "./Auth0Token";

const AuthCookieContentParser = z.object({
  access_token: z.string(),
  id_token_payload: Auth0IdTokenPayloadSchema,
  refresh_token: z.string(),
  token_type: z.string(),
  scope: z.string(),
  expires_at: z.string(),
});

type AuthCookieContent = z.infer<typeof AuthCookieContentParser>;

const shouldUseSecureToken = (protocol: string): boolean =>
  protocol === "https:";

const defaultCookieOptions = (protocol: string): CookieAttributes => ({
  secure: shouldUseSecureToken(protocol),
  sameSite: "Strict",
  path: "/",
});

const authenticationCookieName = "ibe_auth_token";

type AuthInfo =
  | { token: Auth0Token; result: "success" }
  | { token: undefined; result: "no-token-found" }
  | {
      token: undefined;
      result: "invalid-token-shape";
      invalidRawToken: string | undefined;
    };

const parseToken = (authCookieContent: string): AuthInfo => {
  let rawToken: string | undefined;
  try {
    rawToken = decode(authCookieContent);
  } catch (error) {
    rejectToken("Invalid token encoding.");

    return {
      token: undefined,
      result: "invalid-token-shape",
      invalidRawToken: rawToken,
    };
  }

  const parseResult = AuthCookieContentParser.safeParse(JSON.parse(rawToken));
  if (!parseResult.success) {
    rejectToken("Invalid token shape. " + parseResult.error);

    return {
      token: undefined,
      result: "invalid-token-shape",
      invalidRawToken: rawToken,
    };
  }

  const rawCookie = parseResult.data;

  const token: Auth0Token = {
    accessToken: rawCookie.access_token,
    refreshToken: rawCookie.refresh_token,
    idTokenPayload: rawCookie.id_token_payload,
    tokenType: rawCookie.token_type,
    scope: rawCookie.scope,
    expiresAt: parseISO(rawCookie.expires_at),
  };

  return { token, result: "success" };
};

export function readToken(): AuthInfo {
  let retry = 0;
  let authCookieContent;
  while (!authCookieContent && retry <= 10) {
    authCookieContent = Cookie.get(authenticationCookieName);
    if (!authCookieContent) {
      addBreadcrumb({
        message: "token reading failed",
        data: {
          attemptNumber: retry,
        },
      });
      setTimeout(function () {
        // This function is empty
      }, 250);
      retry++;
    }
  }

  if (!authCookieContent) {
    return { token: undefined, result: "no-token-found" };
  }

  addBreadcrumb({
    message: "token has beed read",
    data: {
      retried: retry,
    },
  });
  return parseToken(authCookieContent);
}

export function writeToken(token: Auth0Token): void {
  const rawToken: AuthCookieContent = {
    access_token: token.accessToken,
    refresh_token: token.refreshToken,
    id_token_payload: token.idTokenPayload,
    token_type: token.tokenType,
    scope: token.scope,
    expires_at: token.expiresAt.toISOString(),
  };

  const writedToken = encode(JSON.stringify(rawToken));

  addBreadcrumb({
    message: "token shape before writing",
    data: {
      encodedToken: writedToken,
      cookieOption: {
        ...defaultCookieOptions(document.location.protocol),
        expires: addDays(token.expiresAt, 360),
      },
    },
    level: "debug",
  });

  Cookie.set(authenticationCookieName, writedToken, {
    ...defaultCookieOptions(document.location.protocol),
    expires: addDays(token.expiresAt, 360),
  });

  const readedToken = Cookie.get(authenticationCookieName);
  addBreadcrumb({
    message: "writed and readed token have same shape ?",
    data: {
      writedToken,
      readedToken,
      isSame: writedToken === readedToken,
    },
    level: "debug",
  });
}

export function deleteToken(): void {
  Cookie.remove(
    authenticationCookieName,
    defaultCookieOptions(document.location.protocol),
  );
}

export function rejectToken(reason: string): void {
  console.warn(`Stored token rejected: ${reason}`);
  deleteToken();
}

export function encode(clearTokenString: string): string {
  return Buffer.from(rotate(clearTokenString, 13), "utf-8").toString("base64");
}

export function decode(rawTokenString: string): string {
  return rotate(Buffer.from(rawTokenString, "base64").toString("utf-8"), -13);
}

/**
 * Implementation of the Caesar cipher
 *
 * @see https://en.wikipedia.org/wiki/Caesar_cipher
 */
function rotate(text: string, key: number): string {
  const shift = (letter: string, key: number, startCode: number): string => {
    return String.fromCodePoint(
      ((letter.charCodeAt(0) - startCode + key) % 26) + startCode,
    );
  };

  if (key < 0) {
    return rotate(text, key + 26);
  }

  return text
    .replace(/[a-z]/g, (letter) => shift(letter, key, "a".charCodeAt(0)))
    .replace(/[A-Z]/g, (letter) => shift(letter, key, "A".charCodeAt(0)));
}
