import { usePathname, useRouter } from "next/navigation";
import { createContext, useCallback, useContext, useReducer } from "react";
import { AVAILABLE_LOCALES } from "~/core/locale";

type LocaleProviderState = {
  locale: AVAILABLE_LOCALES;
};

type LocaleProviderAction = {
  type: "SET_LOCALE";
  payload: {
    locale: AVAILABLE_LOCALES;
  };
};

const localeProviderReducer = (
  state: LocaleProviderState,
  action: LocaleProviderAction,
) => {
  switch (action.type) {
    case "SET_LOCALE":
      return {
        ...state,
        locale: action.payload.locale,
      };
    default:
      return state;
  }
};

type LocaleProviderContextType = {
  locale: AVAILABLE_LOCALES;
  onLocaleChange: (locale: AVAILABLE_LOCALES) => void;
};

const AppLocaleContext = createContext<LocaleProviderContextType>({
  locale: AVAILABLE_LOCALES.fr,
  onLocaleChange: () => {
    return;
  },
});

export const LocaleProvider = ({
  children,
  locale,
}: {
  children: React.ReactNode;
  locale: AVAILABLE_LOCALES;
}) => {
  const [state, dispatch] = useReducer(localeProviderReducer, {
    locale: locale,
  });
  const router = useRouter();
  const pathName = usePathname();

  const onLocaleChange = useCallback(
    (locale: AVAILABLE_LOCALES) => {
      dispatch({
        type: "SET_LOCALE",
        payload: {
          locale,
        },
      });

      if (!pathName) {
        return;
      }

      const newPathName = pathName.replace(/^\/.{2}\//, `/${locale}/`);
      router.push(newPathName);
    },
    [pathName, router],
  );

  return (
    <AppLocaleContext.Provider value={{ locale: state.locale, onLocaleChange }}>
      {children}
    </AppLocaleContext.Provider>
  );
};

export const useLocale = () => {
  const context = useContext(AppLocaleContext);

  if (context === undefined) {
    throw new Error("useAppLocale must be used within a LocaleProvider");
  }

  return context;
};
