export enum ViolationCode {
  "UNKNOWN_CODE_ERROR" = "UNKNOWN_CODE_ERROR",

  "AT_LEAST_ONE_OF_ERROR" = "AT_LEAST_ONE_OF_ERROR",
  "INVALID_LENGTH_ERROR" = "INVALID_LENGTH_ERROR",
  "INVALID_CHARACTERS_ERROR" = "INVALID_CHARACTERS_ERROR",
  "INVALID_BANK_CODE_ERROR" = "INVALID_BANK_CODE_ERROR",
  "INVALID_COUNTRY_CODE_ERROR" = "INVALID_COUNTRY_CODE_ERROR",
  "INVALID_CASE_ERROR" = "INVALID_CASE_ERROR",
  "INVALID_IBAN_COUNTRY_CODE_ERROR" = "INVALID_IBAN_COUNTRY_CODE_ERROR",
  "NOT_BLANK_ERROR" = "NOT_BLANK_ERROR",
  "NOT_NUMERIC_ERROR" = "NOT_NUMERIC_ERROR",
  "INVALID_FORMAT_ERROR" = "INVALID_FORMAT_ERROR",
  "NO_SUCH_CHOICE_ERROR" = "NO_SUCH_CHOICE_ERROR",
  "TOO_FEW_ERROR" = "TOO_FEW_ERROR",
  "TOO_MANY_ERROR" = "TOO_MANY_ERROR",
  "MISSING_FIELD_ERROR" = "MISSING_FIELD_ERROR",
  "NO_SUCH_FIELD_ERROR" = "NO_SUCH_FIELD_ERROR",
  "NOT_DIVISIBLE_BY_ERROR" = "NOT_DIVISIBLE_BY_ERROR",
  "NO_SUCH_COUNTRY_ERROR" = "NO_SUCH_COUNTRY_ERROR",
  "NO_SUCH_CURRENCY_ERROR" = "NO_SUCH_CURRENCY_ERROR",
  "INVALID_TIME_ERROR" = "INVALID_TIME_ERROR",
  "NOT_EQUAL_ERROR" = "NOT_EQUAL_ERROR",
  "EXPRESSION_FAILED_ERROR" = "EXPRESSION_FAILED_ERROR",
  "EXPRESSION_LANGUAGE_SYNTAX_ERROR" = "EXPRESSION_LANGUAGE_SYNTAX_ERROR",
  "NOT_FOUND_ERROR" = "NOT_FOUND_ERROR",
  "NOT_READABLE_ERROR" = "NOT_READABLE_ERROR",
  "EMPTY_ERROR" = "EMPTY_ERROR",
  "TOO_LARGE_ERROR" = "TOO_LARGE_ERROR",
  "INVALID_MIME_TYPE_ERROR" = "INVALID_MIME_TYPE_ERROR",
  "INVALID_HOSTNAME_ERROR" = "INVALID_HOSTNAME_ERROR",
  "CHECKSUM_FAILED_ERROR" = "CHECKSUM_FAILED_ERROR",
  "NOT_SUPPORTED_COUNTRY_CODE_ERROR" = "NOT_SUPPORTED_COUNTRY_CODE_ERROR",
  "NOT_IDENTICAL_ERROR" = "NOT_IDENTICAL_ERROR",
  "SIZE_NOT_DETECTED_ERROR" = "SIZE_NOT_DETECTED_ERROR",
  "TOO_WIDE_ERROR" = "TOO_WIDE_ERROR",
  "TOO_NARROW_ERROR" = "TOO_NARROW_ERROR",
  "TOO_HIGH_ERROR" = "TOO_HIGH_ERROR",
  "TOO_FEW_PIXEL_ERROR" = "TOO_FEW_PIXEL_ERROR",
  "TOO_MANY_PIXEL_ERROR" = "TOO_MANY_PIXEL_ERROR",
  "RATIO_TOO_BIG_ERROR" = "RATIO_TOO_BIG_ERROR",
  "RATIO_TOO_SMALL_ERROR" = "RATIO_TOO_SMALL_ERROR",
  "SQUARE_NOT_ALLOWED_ERROR" = "SQUARE_NOT_ALLOWED_ERROR",
  "LANDSCAPE_NOT_ALLOWED_ERROR" = "LANDSCAPE_NOT_ALLOWED_ERROR",
  "PORTRAIT_NOT_ALLOWED_ERROR" = "PORTRAIT_NOT_ALLOWED_ERROR",
  "CORRUPTED_IMAGE_ERROR" = "CORRUPTED_IMAGE_ERROR",
  "INVALID_IP_ERROR" = "INVALID_IP_ERROR",
  "TOO_SHORT_ERROR" = "TOO_SHORT_ERROR",
  "TOO_LONG_ERROR" = "TOO_LONG_ERROR",
  "TYPE_NOT_RECOGNIZED_ERROR" = "TYPE_NOT_RECOGNIZED_ERROR",
  "NOT_FALSE_ERROR" = "NOT_FALSE_ERROR",
  "INVALID_PATTERN_ERROR" = "INVALID_PATTERN_ERROR",
  "INVALID_CHECKSUM_ERROR" = "INVALID_CHECKSUM_ERROR",
  "NOT_NULL_ERROR" = "NOT_NULL_ERROR",
  "MISSING_HYPHEN_ERROR" = "MISSING_HYPHEN_ERROR",
  "NOT_TRUE_ERROR" = "NOT_TRUE_ERROR",
  "INVALID_JSON_ERROR" = "INVALID_JSON_ERROR",
  "NO_SUCH_LANGUAGE_ERROR" = "NO_SUCH_LANGUAGE_ERROR",
  "NO_SUCH_LOCALE_ERROR" = "NO_SUCH_LOCALE_ERROR",
  "IS_BLANK_ERROR" = "IS_BLANK_ERROR",
  "COMPROMISED_PASSWORD_ERROR" = "COMPROMISED_PASSWORD_ERROR",
  "IS_EQUAL_ERROR" = "IS_EQUAL_ERROR",
  "IS_IDENTICAL_ERROR" = "IS_IDENTICAL_ERROR",
  "IS_NULL_ERROR" = "IS_NULL_ERROR",
  "NOT_IN_RANGE_ERROR" = "NOT_IN_RANGE_ERROR",
  "REGEX_FAILED_ERROR" = "REGEX_FAILED_ERROR",
  "TIMEZONE_IDENTIFIER_ERROR" = "TIMEZONE_IDENTIFIER_ERROR",
  "TIMEZONE_IDENTIFIER_IN_ZONE_ERROR" = "TIMEZONE_IDENTIFIER_IN_ZONE_ERROR",
  "TIMEZONE_IDENTIFIER_IN_COUNTRY_ERROR" = "TIMEZONE_IDENTIFIER_IN_COUNTRY_ERROR",
  "TIMEZONE_IDENTIFIER_INTL_ERROR" = "TIMEZONE_IDENTIFIER_INTL_ERROR",
  "INVALID_TYPE_ERROR" = "INVALID_TYPE_ERROR",
  "INVALID_URL_ERROR" = "INVALID_URL_ERROR",
  "INVALID_HYPHEN_PLACEMENT_ERROR" = "INVALID_HYPHEN_PLACEMENT_ERROR",
  "INVALID_VERSION_ERROR" = "INVALID_VERSION_ERROR",
  "INVALID_VARIANT_ERROR" = "INVALID_VARIANT_ERROR",
  "TOO_LOW_ERROR" = "TOO_LOW_ERROR",
  "INVALID_DATE_ERROR" = "INVALID_DATE_ERROR",
  "INVALID_PHONE_NUMBER_ERROR" = "INVALID_PHONE_NUMBER_ERROR",
  "INVALID_MOBILE_PHONE_NUMBER_ERROR" = "INVALID_MOBILE_PHONE_NUMBER_ERROR",

  // Gogaille custom errors
  "PAST_STAY_DATE_ERROR" = "PAST_STAY_DATE_ERROR",
  "PARTIAL_FULL_NAME_ERROR" = "PARTIAL_FULL_NAME_ERROR",

  // Booking errors
  "BOOKING_UNKNOWN_REASON_ERROR" = "BOOKING_UNKNOWN_REASON_ERROR",
  "BOOKING_PAYMENT_REFUSED_ERROR" = "BOOKING_PAYMENT_REFUSED_ERROR",
  "BOOKING_PAYMENT_BLOCKED_CARD_ERROR" = "BOOKING_PAYMENT_BLOCKED_CARD_ERROR",
  "BOOKING_PAYMENT_EXPIRED_CARD_ERROR" = "BOOKING_PAYMENT_EXPIRED_CARD_ERROR",
  "BOOKING_PAYMENT_INVALID_CARD_NUMBER_ERROR" = "BOOKING_PAYMENT_INVALID_CARD_NUMBER_ERROR",
  "BOOKING_PAYMENT_INVALID_CVC_ERROR" = "BOOKING_PAYMENT_INVALID_CVC_ERROR",
  "BOOKING_PAYMENT_NOT_SUPPORTED_BY_SHOPPER_BANK_ERROR" = "BOOKING_PAYMENT_NOT_SUPPORTED_BY_SHOPPER_BANK_ERROR",
  "BOOKING_PAYMENT_NOT_ENOUGH_BALANCE_ERROR" = "BOOKING_PAYMENT_NOT_ENOUGH_BALANCE_ERROR",
  "BOOKING_PAYMENT_RESTRICTED_CARD_ERROR" = "BOOKING_PAYMENT_RESTRICTED_CARD_ERROR",
  "BOOKING_PAYMENT_DECLINED_ERROR" = "BOOKING_PAYMENT_DECLINED_ERROR",
  "BOOKING_OFFER_NOT_FOUND_ERROR" = "BOOKING_OFFER_NOT_FOUND_ERROR",
  "OUT_OF_BOUNDS_NUMBER_OF_ADULTS_CODE" = "OUT_OF_BOUNDS_NUMBER_OF_ADULTS_CODE",
  "OUT_OF_BOUNDS_NUMBER_OF_CHILDREN_CODE" = "OUT_OF_BOUNDS_NUMBER_OF_CHILDREN_CODE",
  "OUT_OF_BOUNDS_NUMBER_OF_BABIES_CODE" = "OUT_OF_BOUNDS_NUMBER_OF_BABIES_CODE",
  "OUT_OF_BOUNDS_CHILDREN_AGES_CODE" = "OUT_OF_BOUNDS_CHILDREN_AGES_CODE",
}

/**
 * Symfony violation codes mapping: uuid to error code
 */
const ViolationCodeMapping: Record<string, ViolationCode> = {
  "f27e6d6c-261a-4056-b391-6673a623531c": ViolationCode.AT_LEAST_ONE_OF_ERROR,
  "66dad313-af0b-4214-8566-6c799be9789c": ViolationCode.INVALID_LENGTH_ERROR,
  "f424c529-7add-4417-8f2d-4b656e4833e2":
    ViolationCode.INVALID_CHARACTERS_ERROR,
  "00559357-6170-4f29-aebd-d19330aa19cf": ViolationCode.INVALID_BANK_CODE_ERROR,
  "1ce76f8d-3c1f-451c-9e62-fe9c3ed486ae":
    ViolationCode.INVALID_COUNTRY_CODE_ERROR,
  "11884038-3312-4ae5-9d04-699f782130c7": ViolationCode.INVALID_CASE_ERROR,
  "29a2c3bb-587b-4996-b6f5-53081364cea5":
    ViolationCode.INVALID_IBAN_COUNTRY_CODE_ERROR,
  "183ad2de-533d-4796-a439-6d3c3852b549": ViolationCode.NOT_BLANK_ERROR,
  "a2ad9231-e827-485f-8a1e-ef4d9a6d5c2e": ViolationCode.NOT_NUMERIC_ERROR,
  "a8faedbf-1c2f-4695-8d22-55783be8efed": ViolationCode.INVALID_FORMAT_ERROR,
  "8e179f1b-97aa-4560-a02f-2a8b42e49df7": ViolationCode.NO_SUCH_CHOICE_ERROR,
  "11edd7eb-5872-4b6e-9f12-89923999fd0e": ViolationCode.TOO_FEW_ERROR,
  "9bd98e49-211c-433f-8630-fd1c2d0f08c3": ViolationCode.TOO_MANY_ERROR,
  "2fa2158c-2a7f-484b-98aa-975522539ff8": ViolationCode.MISSING_FIELD_ERROR,
  "7703c766-b5d5-4cef-ace7-ae0dd82304e9": ViolationCode.NO_SUCH_FIELD_ERROR,
  "bef8e338-6ae5-4caf-b8e2-50e7b0579e69": ViolationCode.TOO_FEW_ERROR,
  "756b1212-697c-468d-a9ad-50dd783bb169": ViolationCode.TOO_MANY_ERROR,
  "6d99d6c3-1464-4ccf-bdc7-14d083cf455c": ViolationCode.NOT_DIVISIBLE_BY_ERROR,
  "8f900c12-61bd-455d-9398-996cd040f7f0": ViolationCode.NO_SUCH_COUNTRY_ERROR,
  "69945ac1-2db4-405f-bec7-d2772f73df52": ViolationCode.NO_SUCH_CURRENCY_ERROR,
  "69819696-02ac-4a99-9ff0-14e127c4d1bc": ViolationCode.INVALID_FORMAT_ERROR,
  "3c184ce5-b31d-4de7-8b76-326da7b2be93": ViolationCode.INVALID_DATE_ERROR,
  "1a9da513-2640-4f84-9b6a-4d99dcddc628": ViolationCode.INVALID_FORMAT_ERROR,
  "d52afa47-620d-4d99-9f08-f4d85b36e33c": ViolationCode.INVALID_DATE_ERROR,
  "5e797c9d-74f7-4098-baa3-94390c447b27": ViolationCode.INVALID_TIME_ERROR,
  "bd79c0ab-ddba-46cc-a703-a7a4b08de310": ViolationCode.INVALID_FORMAT_ERROR,
  "478618a7-95ba-473d-9101-cabd45e49115": ViolationCode.NOT_EQUAL_ERROR,
  "6b3befbc-2f01-4ddf-be21-b57898905284": ViolationCode.EXPRESSION_FAILED_ERROR,
  "1766a3f3-ff03-40eb-b053-ab7aa23d988a":
    ViolationCode.EXPRESSION_LANGUAGE_SYNTAX_ERROR,
  "d2a3fb6e-7ddc-4210-8fbf-2ab345ce1998": ViolationCode.NOT_FOUND_ERROR,
  "c20c92a4-5bfa-4202-9477-28e800e0f6ff": ViolationCode.NOT_READABLE_ERROR,
  "5d743385-9775-4aa5-8ff5-495fb1e60137": ViolationCode.EMPTY_ERROR,
  "df8637af-d466-48c6-a59d-e7126250a654": ViolationCode.TOO_LARGE_ERROR,
  "744f00bc-4389-4c74-92de-9a43cde55534": ViolationCode.INVALID_MIME_TYPE_ERROR,
  "778b7ae0-84d3-481a-9dec-35fdb64b1d78": ViolationCode.TOO_LOW_ERROR,
  "ea4e51d1-3342-48bd-87f1-9e672cd90cad": ViolationCode.TOO_LOW_ERROR,
  "7057ffdb-0af4-4f7e-bd5e-e9acfa6d7a2d": ViolationCode.INVALID_HOSTNAME_ERROR,
  "de78ee2c-bd50-44e2-aec8-3d8228aeadb9":
    ViolationCode.INVALID_COUNTRY_CODE_ERROR,
  "8d3d85e4-784f-4719-a5bc-d9e40d45a3a5":
    ViolationCode.INVALID_CHARACTERS_ERROR,
  "b9401321-f9bf-4dcb-83c1-f31094440795": ViolationCode.CHECKSUM_FAILED_ERROR,
  "c8d318f1-2ecc-41ba-b983-df70d225cf5a": ViolationCode.INVALID_FORMAT_ERROR,
  "e2c259f3-4b46-48e6-b72e-891658158ec8":
    ViolationCode.NOT_SUPPORTED_COUNTRY_CODE_ERROR,
  "2a8cc50f-58a2-4536-875e-060a2ce69ed5": ViolationCode.NOT_IDENTICAL_ERROR,
  "6d55c3f4-e58e-4fe3-91ee-74b492199956": ViolationCode.SIZE_NOT_DETECTED_ERROR,
  "7f87163d-878f-47f5-99ba-a8eb723a1ab2": ViolationCode.TOO_WIDE_ERROR,
  "9afbd561-4f90-4a27-be62-1780fc43604a": ViolationCode.TOO_NARROW_ERROR,
  "7efae81c-4877-47ba-aa65-d01ccb0d4645": ViolationCode.TOO_HIGH_ERROR,
  "aef0cb6a-c07f-4894-bc08-1781420d7b4c": ViolationCode.TOO_LOW_ERROR,
  "1b06b97d-ae48-474e-978f-038a74854c43": ViolationCode.TOO_FEW_PIXEL_ERROR,
  "ee0804e8-44db-4eac-9775-be91aaf72ce1": ViolationCode.TOO_MANY_PIXEL_ERROR,
  "70cafca6-168f-41c9-8c8c-4e47a52be643": ViolationCode.RATIO_TOO_BIG_ERROR,
  "59b8c6ef-bcf2-4ceb-afff-4642ed92f12e": ViolationCode.RATIO_TOO_SMALL_ERROR,
  "5d41425b-facb-47f7-a55a-de9fbe45cb46":
    ViolationCode.SQUARE_NOT_ALLOWED_ERROR,
  "6f895685-7cf2-4d65-b3da-9029c5581d88":
    ViolationCode.LANDSCAPE_NOT_ALLOWED_ERROR,
  "65608156-77da-4c79-a88c-02ef6d18c782":
    ViolationCode.PORTRAIT_NOT_ALLOWED_ERROR,
  "5d4163f3-648f-4e39-87fd-cc5ea7aad2d1": ViolationCode.CORRUPTED_IMAGE_ERROR,
  "b1b427ae-9f6f-41b0-aa9b-84511fbb3c5b": ViolationCode.INVALID_IP_ERROR,
  "949acbb0-8ef5-43ed-a0e9-032dfd08ae45": ViolationCode.TOO_SHORT_ERROR,
  "3171387d-f80a-47b3-bd6e-60598545316a": ViolationCode.TOO_LONG_ERROR,
  "23d21cea-da99-453d-98b1-a7d916fbb339":
    ViolationCode.INVALID_CHARACTERS_ERROR,
  "2881c032-660f-46b6-8153-d352d9706640": ViolationCode.CHECKSUM_FAILED_ERROR,
  "fa54a457-f042-441f-89c4-066ee5bdd3e1":
    ViolationCode.TYPE_NOT_RECOGNIZED_ERROR,
  "d53a91b0-def3-426a-83d7-269da7ab4200": ViolationCode.NOT_FALSE_ERROR,
  "88738dfc-9ed5-ba1e-aebe-402a2a9bf58e": ViolationCode.INVALID_LENGTH_ERROR,
  "3d08ce0-ded9-a93d-9216-17ac21265b65e": ViolationCode.INVALID_PATTERN_ERROR,
  "32089b-0ee1-93ba-399e-aa232e62f2d29d": ViolationCode.INVALID_CHECKSUM_ERROR,
  "60d2f30b-8cfa-4372-b155-9656634de120": ViolationCode.NOT_NULL_ERROR,
  "6a20dd3d-f463-4460-8e7b-18a1b98abbfb": ViolationCode.TOO_SHORT_ERROR,
  "37cef893-5871-464e-8b12-7fb79324833c": ViolationCode.TOO_LONG_ERROR,
  "2983286f-8134-4693-957a-1ec4ef887b15": ViolationCode.MISSING_HYPHEN_ERROR,
  "a663d266-37c2-4ece-a914-ae891940c588":
    ViolationCode.INVALID_CHARACTERS_ERROR,
  "7b6dd393-7523-4a6c-b84d-72b91bba5e1a": ViolationCode.INVALID_CASE_ERROR,
  "b0f92dbc-667c-48de-b526-ad9586d43e85": ViolationCode.CHECKSUM_FAILED_ERROR,
  "2beabf1c-54c0-4882-a928-05249b26e23b": ViolationCode.NOT_TRUE_ERROR,
  "0789c8ad-2d2b-49a4-8356-e2ce63998504": ViolationCode.INVALID_JSON_ERROR,
  "ee65fec4-9a20-4202-9f39-ca558cd7bdf7": ViolationCode.NO_SUCH_LANGUAGE_ERROR,
  "9ff3fdc4-b214-49db-8718-39c315e33d45": ViolationCode.TOO_SHORT_ERROR,
  "d94b19cc-114f-4f44-9cc4-4138e80a87b9": ViolationCode.TOO_LONG_ERROR,
  "35e6a710-aa2e-4719-b58e-24b35749b767":
    ViolationCode.INVALID_CHARACTERS_ERROR,
  "079d7420-2d13-460c-8756-de810eeb37d2": ViolationCode.TOO_HIGH_ERROR,
  "30fbb013-d015-4232-8b3b-8f3be97a7e14": ViolationCode.TOO_HIGH_ERROR,
  "a0af4293-1f1a-4a1c-a328-979cba6182a2": ViolationCode.NO_SUCH_LOCALE_ERROR,
  "dfad6d23-1b74-4374-929b-5cbb56fc0d9e":
    ViolationCode.INVALID_CHARACTERS_ERROR,
  "4d760774-3f50-4cd5-a6d5-b10a3299d8d3": ViolationCode.CHECKSUM_FAILED_ERROR,
  "c1051bb4-d103-4f74-8988-acbcafc7fdc3": ViolationCode.IS_BLANK_ERROR,
  "d9bcdbfe-a9d6-4bfa-a8ff-da5fd93e0f6d":
    ViolationCode.COMPROMISED_PASSWORD_ERROR,
  "aa2e33da-25c8-4d76-8c6c-812f02ea89dd": ViolationCode.IS_EQUAL_ERROR,
  "4aaac518-0dda-4129-a6d9-e216b9b454a0": ViolationCode.IS_IDENTICAL_ERROR,
  "ad32d13f-c3d4-423b-909a-857b961eb720": ViolationCode.IS_NULL_ERROR,
  "ad9a9798-7a99-4df7-8ce9-46e416a1e60b":
    ViolationCode.INVALID_CHARACTERS_ERROR,
  "04b91c99-a946-4221-afc5-e65ebac401eb": ViolationCode.NOT_IN_RANGE_ERROR,
  "2d28afcb-e32e-45fb-a815-01c431a86a69": ViolationCode.TOO_HIGH_ERROR,
  "76454e69-502c-46c5-9643-f447d837c4d5": ViolationCode.TOO_LOW_ERROR,
  "de1e3db3-5ed4-4941-aae4-59f3667cc3a3": ViolationCode.REGEX_FAILED_ERROR,
  "9d27b2bb-f755-4fbf-b725-39b1edbdebdf": ViolationCode.INVALID_FORMAT_ERROR,
  "8532f9e1-84b2-4d67-8989-0818bc38533b": ViolationCode.INVALID_TIME_ERROR,
  "5ce113e6-5e64-4ea2-90fe-d2233956db13":
    ViolationCode.TIMEZONE_IDENTIFIER_ERROR,
  "b57767b1-36c0-40ac-a3d7-629420c775b8":
    ViolationCode.TIMEZONE_IDENTIFIER_IN_ZONE_ERROR,
  "c4a22222-dc92-4fc0-abb0-d95b268c7d0b":
    ViolationCode.TIMEZONE_IDENTIFIER_IN_COUNTRY_ERROR,
  "45863c26-88dc-41ba-bf53-c73bd1f7e90d":
    ViolationCode.TIMEZONE_IDENTIFIER_INTL_ERROR,
  "ba785a8c-82cb-4283-967c-3cf342181b40": ViolationCode.INVALID_TYPE_ERROR,
  "7b44804e-37d5-4df4-9bdd-b738d4a45bb4": ViolationCode.TOO_SHORT_ERROR,
  "9608249f-6da1-4d53-889e-9864b58c4d37": ViolationCode.TOO_LONG_ERROR,
  "e4155739-5135-4258-9c81-ae7b44b5311e":
    ViolationCode.INVALID_CHARACTERS_ERROR,
  "df8cfb9a-ce6d-4a69-ae5a-eea7ab6f278b": ViolationCode.TOO_LARGE_ERROR,
  "57c2f299-1154-4870-89bb-ef3b1f5ad229": ViolationCode.INVALID_URL_ERROR,
  "aa314679-dac9-4f54-bf97-b2049df8f2a3": ViolationCode.TOO_SHORT_ERROR,
  "494897dd-36f8-4d31-8923-71a8d5f3000d": ViolationCode.TOO_LONG_ERROR,
  "51120b12-a2bc-41bf-aa53-cd73daf330d0":
    ViolationCode.INVALID_CHARACTERS_ERROR,
  "98469c83-0309-4f5d-bf95-a496dcaa869c":
    ViolationCode.INVALID_HYPHEN_PLACEMENT_ERROR,
  "21ba13b4-b185-4882-ac6f-d147355987eb": ViolationCode.INVALID_VERSION_ERROR,
  "164ef693-2b9d-46de-ad7f-836201f0c2db": ViolationCode.INVALID_VARIANT_ERROR,
  "6bba5239-250c-369a-9984-1d9ef8645ac9":
    ViolationCode.INVALID_PHONE_NUMBER_ERROR,
  "3db837ba-f00b-42f9-b6cf-e33ca5871f90":
    ViolationCode.INVALID_MOBILE_PHONE_NUMBER_ERROR,

  // Gogaille Custom Errors
  "26037b9c-a11e-43d7-9e64-59dd4f1b01c0": ViolationCode.PAST_STAY_DATE_ERROR,
  "26037b9c-a11e-43d7-9e64-59dd4f1b01c1": ViolationCode.PARTIAL_FULL_NAME_ERROR,

  // Booking issue
  "47d7df8c-8b2b-4b02-9484-94375de98473":
    ViolationCode.BOOKING_UNKNOWN_REASON_ERROR,

  "60e5d468-9008-4358-8bec-c2ce3c53ff66":
    ViolationCode.BOOKING_PAYMENT_REFUSED_ERROR,
  "b1dc245b-d96d-4299-8289-fe75cfa9c17a":
    ViolationCode.BOOKING_PAYMENT_BLOCKED_CARD_ERROR,
  "e9940163-0258-48cd-8592-b7b51bf7ad36":
    ViolationCode.BOOKING_PAYMENT_EXPIRED_CARD_ERROR,
  "3f6dab8f-f1ba-4945-b93c-b74254e403f0":
    ViolationCode.BOOKING_PAYMENT_INVALID_CARD_NUMBER_ERROR,
  "ce04ef5b-e19a-45aa-b8d5-336c88befd3e":
    ViolationCode.BOOKING_PAYMENT_INVALID_CVC_ERROR,
  "1cbdc61e-a3da-47d7-a539-3d6ac7525c93":
    ViolationCode.BOOKING_PAYMENT_NOT_SUPPORTED_BY_SHOPPER_BANK_ERROR,
  "d538f493-977e-4a8b-8710-48776bb8ee07":
    ViolationCode.BOOKING_PAYMENT_NOT_ENOUGH_BALANCE_ERROR,
  "47fe5f92-9076-415a-a207-01dffb7bddb4":
    ViolationCode.BOOKING_PAYMENT_RESTRICTED_CARD_ERROR,
  "57507caa-db8e-4327-b72f-8d0326e6817f":
    ViolationCode.BOOKING_PAYMENT_DECLINED_ERROR,

  "7e66f9a0-0f75-4606-ae19-1d78d37f41ed":
    ViolationCode.BOOKING_OFFER_NOT_FOUND_ERROR,

  "4d22a950-3ef1-4ca7-b1be-62a6b6a2d605":
    ViolationCode.OUT_OF_BOUNDS_NUMBER_OF_ADULTS_CODE,
  "aa72e5ef-b15e-4f71-8158-8255b9a55dc9":
    ViolationCode.OUT_OF_BOUNDS_NUMBER_OF_CHILDREN_CODE,
  "c3d54bf7-3718-445d-8ed3-e7e8df5967fc":
    ViolationCode.OUT_OF_BOUNDS_NUMBER_OF_BABIES_CODE,
  "9df213a0-9166-413e-8906-0ba33b9def69":
    ViolationCode.OUT_OF_BOUNDS_CHILDREN_AGES_CODE,
};

export const mapViolationCode = (code: string): ViolationCode =>
  ViolationCodeMapping[code] ?? ViolationCode.UNKNOWN_CODE_ERROR;
