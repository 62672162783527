import { useRouter } from "next/compat/router";
import { useMemo } from "react";
import {
  AVAILABLE_LOCALES,
  DEFAULT_LOCALE,
  guardAvailableLocale,
} from "~/core/locale";
import { useLocale as useAppLocale } from "~/core/useAppLocale";

export const useLocale = (): AVAILABLE_LOCALES => {
  const router = useRouter();
  const { locale: appLocale } = useAppLocale();

  const locale = useMemo<AVAILABLE_LOCALES>(() => {
    if (!router) {
      return appLocale;
    }

    const locale =
      typeof router?.locale === "string" ? router.locale : appLocale;

    try {
      guardAvailableLocale(locale);
      return locale;
    } catch {
      console.warn(`Invalid locale ${locale}`);
      return DEFAULT_LOCALE;
    }
  }, [appLocale, router]);

  return locale;
};
