import { useButton } from "@react-aria/button";
import { AriaLinkOptions, useLink } from "@react-aria/link";
import { AriaButtonProps } from "@react-types/button";
import NextLink from "next/link";
import React, { RefObject } from "react";
import styled from "styled-components";
import { Route } from "~/core/routes";
import {
  SpinnerContainer,
  SpinnerLoader,
  defaultButtonStyle,
  styledIconOnly,
  styledKind,
  styledSize,
} from "~/guidelines/Button/StyledComponents";
import { FocusRing } from "~/guidelines/Focus";
import IconSvg from "~/guidelines/Icon/IconSvg";

export interface BaseButtonProps {
  readonly type?: "button" | "submit" | "reset";
  readonly size?: "small" | "normal" | "large";
  readonly kind?: "primary" | "secondary" | "tertiary" | "quaternary";
  readonly fluid?: boolean;
  readonly variant?: "light_background" | "dark_background";
  readonly leadingIcon?: React.ReactNode;
  readonly trailingIcon?: React.ReactNode;
  readonly iconColor?: string;
  readonly isLoading?: boolean;
  readonly className?: string;
}

export interface BaseLinkAsButtonProps {
  readonly href: Route;
  readonly target?: string;
  readonly rel?: string;
  readonly children: React.ReactNode;
}

export interface IconOnlyBaseButtonProps {
  readonly type?: "button" | "submit" | "reset";
  readonly size?: "small" | "normal" | "large";
  readonly kind?: "primary" | "secondary" | "tertiary" | "quaternary";
  readonly variant?: "light_background" | "dark_background";
  readonly icon?: React.ReactNode;
  readonly iconColor?: string;
  readonly children?: never;
  readonly isLoading?: boolean;
  readonly className?: string;
}

interface StyledBaseButtonProps {
  readonly $type?: "button" | "submit" | "reset";
  readonly $size?: "small" | "normal" | "large";
  readonly $kind?: "primary" | "secondary" | "tertiary" | "quaternary";
  readonly $fluid?: boolean;
  readonly $variant?: "light_background" | "dark_background";
  readonly $leadingIcon?: React.ReactNode;
  readonly $trailingIcon?: React.ReactNode;
  readonly $iconColor?: string;
  readonly $isLoading?: boolean;
  readonly $isIconOnly?: boolean;
}

const ButtonLabel = styled.span.attrs((props: StyledBaseButtonProps) => ({
  type: props.$type ?? "button",
  kind: props.$kind ?? "primary",
  size: props.$size ?? "normal",
  variant: props.$variant ?? "light_background",
}))<StyledBaseButtonProps>`
  margin-bottom: -2px; /* Compensate the font baseline */
`;

const LinkAsButton = styled(NextLink).attrs(
  (props: StyledBaseButtonProps & { $isIconOnly?: boolean }) => ({
    $type: props.$type ?? "button",
    $kind: props.$kind ?? "primary",
    $size: props.$size ?? "normal",
    $fluid: props.$fluid ?? false,
    $variant: props.$variant ?? "light_background",
    $isIconOnly: props.$isIconOnly ?? false,
    $isLoading: props.$isLoading ?? false,
    $iconColor: props.$iconColor ?? undefined,
  }),
)<StyledBaseButtonProps>`
  ${({ $fluid }) => defaultButtonStyle($fluid)};

  ${({ $size }) => styledSize($size)};

  ${({ $kind, $variant, $iconColor }) =>
    styledKind($kind, $variant, $iconColor)};

  ${({ $isIconOnly, $size }) => styledIconOnly($isIconOnly, $size)};

  & ${SpinnerContainer} {
    opacity: ${({ $isLoading }) => ($isLoading ? 1 : 0)};
  }

  & ${SpinnerLoader} {
    transition: opacity 250ms ease-out;
  }

  & ${ButtonLabel}, & ${IconSvg} {
    transition:
      visibility 250ms ease-out,
      opacity 250ms ease-out;
    visibility: ${({ $isLoading }) => ($isLoading ? "hidden" : "visible")};
    opacity: ${({ $isLoading }) => ($isLoading ? 0 : 1)};
  }
`;

const BaseButton = styled.button.attrs(
  (props: StyledBaseButtonProps & { $isIconOnly?: boolean }) => ({
    $type: props.$type ?? "button",
    $kind: props.$kind ?? "primary",
    $size: props.$size ?? "normal",
    $fluid: props.$fluid ?? false,
    $variant: props.$variant ?? "light_background",
    $isIconOnly: props.$isIconOnly ?? false,
    $isLoading: props.$isLoading ?? false,
    $iconColor: props.$iconColor ?? undefined,
  }),
)<StyledBaseButtonProps>`
  ${({ $fluid }) => defaultButtonStyle($fluid)};

  ${({ $size }) => styledSize($size)};

  ${({ $kind, $variant, $iconColor }) =>
    styledKind($kind, $variant, $iconColor)};

  ${({ $isIconOnly, $size }) => styledIconOnly($isIconOnly, $size)};

  & ${SpinnerContainer} {
    opacity: ${({ $isLoading }) => ($isLoading ? 1 : 0)};
  }

  & ${SpinnerLoader} {
    transition: opacity 250ms ease-out;
  }

  & ${ButtonLabel}, & ${IconSvg} {
    transition:
      visibility 250ms ease-out,
      opacity 250ms ease-out;
    visibility: ${({ $isLoading }) => ($isLoading ? "hidden" : "visible")};
    opacity: ${({ $isLoading }) => ($isLoading ? 0 : 1)};
  }
`;

export type ButtonProps = AriaButtonProps<"button"> & BaseButtonProps;

export type LinkAsButtonProps = AriaLinkOptions &
  BaseButtonProps &
  BaseLinkAsButtonProps;

export type LinkOrButtonProps = (ButtonProps | LinkAsButtonProps) & {
  prefetch?: boolean;
};

export const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  LinkOrButtonProps
>(function ButtonWithRef(
  {
    href,
    target,
    rel,
    prefetch,
    size,
    kind,
    fluid,
    variant,
    leadingIcon,
    trailingIcon,
    iconColor,
    children,
    type,
    isLoading,
    className,
    ...props
  },
  forwardedRef,
) {
  const { linkProps } = useLink(
    props as AriaLinkOptions,
    forwardedRef as RefObject<HTMLAnchorElement>,
  );

  const { buttonProps } = useButton(
    {
      ...props,
      type,
      children,
      isDisabled: isLoading || props.isDisabled,
    } as AriaButtonProps<"button">,
    forwardedRef as RefObject<HTMLButtonElement>,
  );

  const buttonContent = (
    <>
      {leadingIcon}

      {children !== undefined ? (
        <ButtonLabel size={size} kind={kind}>
          {children}
        </ButtonLabel>
      ) : undefined}

      {trailingIcon}
    </>
  );

  if (href !== undefined) {
    return (
      <FocusRing>
        <LinkAsButton
          {...linkProps}
          ref={forwardedRef as RefObject<HTMLAnchorElement>}
          // The href is passed by the parent next link
          target={target}
          rel={rel}
          className={className}
          href={href}
          prefetch={prefetch}
          $size={size}
          $kind={kind}
          $fluid={fluid}
          $variant={variant}
          $iconColor={iconColor}
        >
          {buttonContent}
        </LinkAsButton>
      </FocusRing>
    );
  }

  return (
    <FocusRing>
      <BaseButton
        {...buttonProps}
        ref={forwardedRef as RefObject<HTMLButtonElement>}
        $size={size}
        $kind={kind}
        $fluid={fluid}
        $variant={variant}
        $isLoading={isLoading}
        $iconColor={iconColor}
        className={className}
      >
        {buttonContent}
        {isLoading ? (
          <SpinnerContainer>
            <SpinnerLoader />
          </SpinnerContainer>
        ) : undefined}
      </BaseButton>
    </FocusRing>
  );
});

export type IconOnlyLinkAsButtonProps = AriaLinkOptions &
  IconOnlyBaseButtonProps &
  BaseLinkAsButtonProps;

export type LinkOrIconOnlyButtonProps =
  | IconOnlyBaseButtonProps
  | IconOnlyLinkAsButtonProps;

type IconOnlyButtonProps = (AriaButtonProps<"button"> &
  LinkOrIconOnlyButtonProps) & { prefetch?: boolean };

export const IconOnlyButton = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  IconOnlyButtonProps
>(function IconOnlyButtonWithRef(
  {
    href,
    size,
    kind,
    icon,
    variant,
    isLoading,
    iconColor,
    prefetch,
    className,
    ...props
  },
  forwardedRef,
) {
  const { linkProps } = useLink(
    props as AriaLinkOptions,
    forwardedRef as RefObject<HTMLAnchorElement>,
  );

  const { buttonProps } = useButton(
    { ...props, isDisabled: isLoading || props.isDisabled },
    forwardedRef as RefObject<HTMLButtonElement>,
  );

  if (href !== undefined) {
    return (
      <FocusRing>
        <LinkAsButton
          {...linkProps}
          ref={forwardedRef as RefObject<HTMLAnchorElement>}
          $size={size}
          $kind={kind}
          $variant={variant}
          $iconColor={iconColor}
          $isIconOnly
          href={href}
          prefetch={prefetch}
          className={className}
        >
          {icon}
        </LinkAsButton>
      </FocusRing>
    );
  }

  return (
    <FocusRing>
      <BaseButton
        {...buttonProps}
        ref={forwardedRef as RefObject<HTMLButtonElement>}
        $size={size}
        $kind={kind}
        $variant={variant}
        $iconColor={iconColor}
        $isLoading={isLoading}
        $isIconOnly
        className={className}
      >
        {icon}
        {isLoading ? (
          <SpinnerContainer>
            <SpinnerLoader />
          </SpinnerContainer>
        ) : undefined}
      </BaseButton>
    </FocusRing>
  );
});
