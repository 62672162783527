import { LocaleData } from "@lingui/core";
import { en, fr } from "make-plural/plurals";
import { AVAILABLE_LOCALES } from "~/core/locale";

const localesData: Record<string, LocaleData> = {
  default: { plurals: en },
  en: { plurals: en },
  fr: { plurals: fr },
};

if (process.env.NODE_ENV !== "production") {
  for (const oneAvailableLocale in AVAILABLE_LOCALES) {
    if (localesData[oneAvailableLocale] === undefined) {
      console.warn(
        `The locale data (pluralization rules) are missing for the locale ${oneAvailableLocale}. Please add them in the "localesData" export.`,
      );
    }
  }
}

export type LocalesData = typeof localesData;

export default localesData;
